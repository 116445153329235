import styled from "styled-components/macro";
import Icon from "@mdi/react";

interface IStyledChecklistSectionIconElementProps {
  open: boolean;
}

export const ChecklistSectionChecksContainerElement = styled.div``;

export const StyledChecklistSectionIconElement = styled(
  Icon,
)<IStyledChecklistSectionIconElementProps>`
  transform: rotate(${(props) => (props.open ? "90deg" : "0deg")});
  transition: 0.2s;
  transition-timing-function: ease-out;
`;
