import { SectionEntity } from "../../../core/domain/entities/checklist/SectionEntity";
import { ISectionDTO } from "../common/types";
import { CheckMapper } from "./CheckMapper";

export class SectionMapper {
  public static fromDTO(dto: ISectionDTO): SectionEntity {
    return SectionEntity.existing(
      dto.uid,
      dto.title,
      dto.checks.map((c) => CheckMapper.fromDTO({ ...c })),
    );
  }
}
