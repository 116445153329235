import { WorkInstructionEntity } from "../domain/entities/checklist/WorkInstructionEntity";
import { IContentResult } from "../results/Result";
import { IGetWorkInstructionByIdRequest } from "../../infrastructure/api/endpoints/common/request.types";
import { ChecklistRepository } from "../../infrastructure/repositories/ChecklistRepository";

export class WorkInstructionQuery {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    request: IGetWorkInstructionByIdRequest,
  ): Promise<IContentResult<WorkInstructionEntity>> {
    return await this._checklistRepository.getInstructionById(request);
  }
}
