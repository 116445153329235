import { Html5Qrcode } from "html5-qrcode";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../context/SettingsManager";

interface IListenerResult {
  codeID: string;
  dataAsText: string;
  rawData: string[];
}

export const useMemor10Scanner = () => {
  const BASE_URI = "http://127.0.0.1:8080/scan";
  const { isMemor10 } = useContext(SettingsContext);
  const [scanning, setScanning] = useState(false);

  const validateDeviceIsMemor10 = (): boolean => {
    return isMemor10;
  };

  const scan = async (onResult: (result: string) => void): Promise<void> => {
    setScanning(true);

    fetch(`${BASE_URI}?action=read_listener&output=json`)
      .then(async (res) => {
        const { dataAsText } = (await res.json()) as IListenerResult;

        onResult(dataAsText);

        setScanning(false);
      })
      .catch(() => {
        setScanning(false);
      });

    fetch(`${BASE_URI}?action=start_trigger&timeout=5000`);

    fetch(`${BASE_URI}?action=stop_listener`)
      .then(() => {
        setScanning(false);
      })
      .catch(() => {
        setScanning(false);
      });
  };

  return { scan, validateDeviceIsMemor10, scanning };
};

export const useCameraScanner = (readerId: string) => {
  const [reader, setReader] = useState<Html5Qrcode>();
  const { isZebraScanner } = useContext(SettingsContext);

  const validateDeviceIsCamera = async (): Promise<boolean> => {
    if (isZebraScanner) {
      return false;
    }

    try {
      return (await Html5Qrcode.getCameras()).length > 0;
    } catch (e) {
      return false;
    }
  };

  const resetCamera = () => {
    reader?.stop();
    setReader(undefined);
  };

  const initCamera = async (
    onResult: (result: string) => void,
  ): Promise<void> => {
    if (reader) return;

    const html5QrCode = new Html5Qrcode(readerId);

    setReader(html5QrCode);

    const cameras = await Html5Qrcode.getCameras();

    return new Promise((r, e) => {
      try {
        html5QrCode.start(
          (cameras[1] ?? cameras[0]).id,
          {
            fps: 30,
            videoConstraints: {
              advanced: [{ facingMode: "environment" }],
            },
            qrbox: { width: 250, height: 250 },
          },
          (decodedText) => {
            onResult(decodedText);
          },
          (errorMessage) => {
            return e(errorMessage);
          },
        );

        r();
      } catch (e: unknown) {
        const { message } = e as Error;

        throw Error(message);
      }
    });
  };

  return { initCamera, validateDeviceIsCamera, resetCamera };
};

type ScanMethod = "memor10" | "camera" | "text";

export const useScanner = (readerId: string) => {
  const {
    scan: memor10Scan,
    validateDeviceIsMemor10,
    scanning,
  } = useMemor10Scanner();
  const { initCamera, validateDeviceIsCamera, resetCamera } =
    useCameraScanner(readerId);
  const [scanMethod, setScanMethod] = useState<ScanMethod>("text");
  const [scanResult, setScanResult] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      await setDefaultScanMethod();
    })();
  }, []);

  const overrideScanMethod = (method: ScanMethod) => {
    setScanMethod(method);

    if (scanMethod === "camera") {
      resetCamera();
    }
  };

  const setDefaultScanMethod = async (): Promise<void> => {
    if (validateDeviceIsMemor10()) {
      setScanMethod("memor10");
    } else if (await validateDeviceIsCamera()) {
      setScanMethod("camera");
    } else {
      setScanMethod("text");
    }
  };

  const reset = () => {
    setScanResult(undefined);
  };

  const scan = async (): Promise<void> => {
    if (validateDeviceIsMemor10()) {
      await memor10Scan((result) => {
        setScanResult(result.trim());
      });
    } else {
      await initCamera((result) => {
        setScanResult(result.trim());
      });
    }
  };

  return {
    scan,
    scanResult,
    scanMethod,
    reset,
    resetCamera,
    overrideScanMethod,
    setDefaultScanMethod,
    scanning,
  };
};
