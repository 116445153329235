import React from "react";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import SettingsTemplate from "../../templates/SettingsTemplate";

export const SettingsPage: React.FC = () => {
  return (
    <LayoutV2>
      <SettingsTemplate />
    </LayoutV2>
  );
};
