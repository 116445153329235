import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckPhotoEntity extends ICheckBaseEntity {
  photos: { uid: string; uri: string }[] | null;
  minPhotoCount: number | null;
  maxPhotoCount: number | null;
}

export class CheckPhotoEntity
  extends CheckBaseEntity
  implements ICheckPhotoEntity
{
  public readonly photos: { uid: string; uri: string }[] | null;
  public minPhotoCount: number | null = null;
  public maxPhotoCount: number | null = null;

  protected constructor(check: ICheckPhotoEntity) {
    super(check);

    this.photos = check.photos;
    this.minPhotoCount = check.minPhotoCount;
    this.maxPhotoCount = check.maxPhotoCount;
  }

  public static new(check: Omit<ICheckPhotoEntity, "uid">): CheckPhotoEntity {
    return new CheckPhotoEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckPhotoEntity): CheckPhotoEntity {
    return new CheckPhotoEntity(check);
  }
}
