import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export type YesNoCheckComment = {
  value: string;
  createdAt: string;
  author: string;
};

export interface ICheckYesNoEntity extends ICheckBaseEntity {
  answer: boolean | null;
  commentRequiredOnNo: boolean;
  commentRequiredOnYes: boolean;
  comment: YesNoCheckComment | null;
  commentOnNoTitle: string | null;
  commentOnYesTitle: string | null;
}

export class CheckYesNoEntity
  extends CheckBaseEntity
  implements ICheckYesNoEntity
{
  public readonly answer: boolean | null;
  public readonly commentRequiredOnNo: boolean = false;
  public readonly commentRequiredOnYes: boolean = false;
  public readonly comment: YesNoCheckComment | null = null;
  public readonly commentOnNoTitle: string | null = null;
  public readonly commentOnYesTitle: string | null = null;

  protected constructor(check: ICheckYesNoEntity) {
    super(check);

    this.answer = check.answer;
    this.commentRequiredOnNo = check.commentRequiredOnNo;
    this.commentRequiredOnYes = check.commentRequiredOnYes;
    this.comment = check.comment;
    this.commentOnYesTitle = check.commentOnYesTitle;
    this.commentOnNoTitle = check.commentOnNoTitle;
  }

  public static new(check: Omit<ICheckYesNoEntity, "uid">): CheckYesNoEntity {
    return new CheckYesNoEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckYesNoEntity): CheckYesNoEntity {
    return new CheckYesNoEntity(check);
  }
}
