import { motion } from "framer-motion";
import styled from "styled-components/macro";

export const TextareaContainerElement = styled.div<{ disabled?: boolean }>`
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.55 : 1)};
`;

export const TextareaLabelMotionElement = styled(motion.label)`
  position: absolute;
  transform: translate(13px, 16px) scale(1);
  font-size: 16px;
  line-height: 23px;
  animation-fill-mode: forwards;
  transform-origin: left;
  padding: 8px;
  pointer-events: none;
`;
