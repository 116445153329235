import styled from "styled-components/macro";

interface ITypographyLabelLargeElementProps {
  lineHeight?: boolean;
}

export const TypographyLabelLargeElement = styled.p<ITypographyLabelLargeElementProps>`
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.1px;
`;
