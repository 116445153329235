import TimeAgo from "javascript-time-ago";
import React from "react";
import { IActivityViewModel } from "../../../application/viewModels/ActivityViewModel";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import nl from "../../../time-ago-nl.json";
import Grid from "../../atoms/Grid";
import Activity from "../../molecules/Activity";

interface IActivityTimelineProps {
  activities: IActivityViewModel[];
  signed: boolean;
  check: ICheckBaseViewModel;
}

TimeAgo.addDefaultLocale(nl);

export const ActivityTimeline: React.FC<IActivityTimelineProps> = ({
  activities,
  signed,
  check,
}) => {
  if (signed && activities.length === 0) {
    return <></>;
  }

  return (
    <Grid item spacing={0} padded={signed}>
      {activities.map((a, idx) => (
        <Activity
          author={a.author}
          onDelete={() => {
            (check as ICheckBaseViewModel).deleteComment(a.uid);
          }}
          onEdit={(value) => {
            (check as ICheckBaseViewModel).editComment(a.uid, value);
          }}
          first={idx === 0}
          type={a.commentType}
          key={a.createdAt}
          title="Activiteit"
          text={a.message}
          timestamp={new Date(a.createdAt)}
          photos={a.photos}
          signed={signed}
        />
      ))}
    </Grid>
  );
};
