import styled from "styled-components/macro";

export const TextFieldElement = styled.input`
  width: 100%;
  background: transparent;
  outline: ${(props) => props.theme.outline.main} 1px solid;
  border-radius: 4px;
  font-size: 16px;
  padding: 15.5px 13px;
  color: ${(props) => props.theme.surface.onMain};
  line-height: 23px;
  resize: none;
  border: none;
`;
