import { mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import Grid from "../../atoms/Grid";
import TextField from "../../atoms/TextField";
import TypographyBodyLarge from "../../atoms/TypographyBodyLarge";
import Drawer from "../Drawer";
import {
  SelectContainerElement,
  SelectIconWrapper,
  SelectLabelMotionElement,
} from "./styled";

interface ITextareaProps extends TextareaAutosizeProps {
  label: string;
  drawerLabel: string;
  items: { label: string }[];
  onChangeValue: (value: string) => void;
}

const labelAnimationVariants = {
  unfocused: {
    x: 13,
    y: 8,
    scale: 1,
    transition: { type: "tween", ease: [0, 0, 0.2, 1], duration: 0.2 },
  },
  focused: {
    x: 13,
    y: -19,
    scale: 0.75,
    transition: { type: "tween", ease: [0, 0, 0.2, 1], duration: 0.2 },
  },
};

export const Select = ({
  label,
  drawerLabel,
  items,
  onChangeValue,
}: ITextareaProps): JSX.Element => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (label: string) => {
    setValue(label);
    onChangeValue(label);
    setFocus(false);
  };

  return (
    <>
      <SelectContainerElement onClick={() => setFocus(true)}>
        <SelectLabelMotionElement
          className="bg-background"
          variants={labelAnimationVariants}
          animate={focus || value ? "focused" : "unfocused"}
        >
          {label}
        </SelectLabelMotionElement>
        <div style={{ pointerEvents: "none" }}>
          <TextField value={value} />
        </div>
        <SelectIconWrapper>
          <Icon path={mdiMenuDown} size={1} />
        </SelectIconWrapper>
      </SelectContainerElement>
      <Drawer open={focus} onClose={() => setFocus(false)} title={drawerLabel}>
        <Grid item spacing={2}>
          <div
            key={"none"}
            onClick={() => {
              handleChange("");
            }}
          >
            <TypographyBodyLarge>Geen</TypographyBodyLarge>
          </div>
          {items.map((l) => (
            <div
              key={l.label}
              onClick={() => {
                handleChange(l.label);
              }}
            >
              <TypographyBodyLarge>{l.label}</TypographyBodyLarge>
            </div>
          ))}
        </Grid>
      </Drawer>
    </>
  );
};
