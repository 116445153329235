import { ChecklistEntity } from "../../../core/domain/entities/checklist/ChecklistEntity";
import { IChecklistDTO } from "../common/types";
import { SectionMapper } from "./SectionMapper";

export class ChecklistMapper {
  public static fromDTO(dto: IChecklistDTO): ChecklistEntity {
    return ChecklistEntity.existing(
      dto.id,
      dto.uid,
      dto.serialNumber,
      dto.title,
      dto.specialFunctions,
      dto.accepted,
      dto.state,
      dto.groups.map((s) => SectionMapper.fromDTO(s)),
      dto.createdBy,
      dto.acceptedBy,
    );
  }
}
