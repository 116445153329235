import styled, { css } from "styled-components/macro";
import { ButtonVariant } from "./Button";
import { DefaultTheme } from "styled-components";

interface IVariantProps {
  color: Extract<keyof DefaultTheme, "primary" | "secondary">;
}

interface IButtonElementProps extends IVariantProps {
  variant: ButtonVariant;
  disabled: boolean;
}

const filledVariant = css<IVariantProps>`
  background: ${(props) => props.theme[props.color].main};
  color: ${(props) => props.theme[props.color].onMain};
`;

const outlinedVariant = css<IVariantProps>`
  outline: 1px solid ${(props) => props.theme.outline.main};
  color: ${(props) => props.theme[props.color].main};
  background: transparent;
`;

const textVariant = css<IVariantProps>`
  color: ${(props) => props.theme[props.color].main};
  background: transparent;
`;

const cardVariant = css<IVariantProps>`
  outline: 1px solid ${(props) => props.theme.outline.main};
  color: ${(props) => props.theme[props.color].main};
  background: ${(props) => props.theme[props.color].main};
`;

const variants = {
  ["filled"]: filledVariant,
  ["outlined"]: outlinedVariant,
  ["text"]: textVariant,
  ["card"]: cardVariant,
};

export const ButtonElement = styled.button<IButtonElementProps>`
  border: none;
  border-radius: 100px;
  padding: 10px 24px;
  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
  ${(props) => variants[props.variant]}
`;
