import styled from "styled-components/macro";

export const PhotoInputContainerElement = styled.div`
  outline: 1px dashed ${(props) => props.theme.outline.main};
  padding: 16px;
  border-radius: 8px;
`;

export const PhotoInputButtonLabel = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;
  color: ${(props) => props.theme.outline.main};
`;

export const ThumbnailContainerElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
`;

export const ThumbnailElement = styled.div`
  position: relative;
`;

export const ThumbnailDeleteButtonElement = styled.div`
  width: 24px;
  height: 24px;
  border: none;
  background: ${(props) => props.theme.surfaceVariant.main};
  color: ${(props) => props.theme.surfaceVariant.onMain};
  border-radius: 24px;
  position: absolute;
  right: -12px;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThumbnailImageElement = styled.div<{ src: string }>`
  width: 75px;
  height: 75px;
  background: url(${(props) => props.src}) center;
  background-size: cover;
`;
