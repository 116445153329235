import { ICheckBaseViewModel } from "../../application/viewModels/CheckBaseViewModel";
import { createObservableStateHook } from "./createObservableStateHook";

// Todo: Find a way to make this less stupid and without the need of iterating over the properties...
// We won't use this hook for now.
export const useCheck = (check: ICheckBaseViewModel): ICheckBaseViewModel => {
  Object.keys(check).forEach((k) => {
    createObservableStateHook(() => check[k])();
  });

  return check;
};
