import styled from "styled-components/macro";

export const CheckPageContainer = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}px) {
    /* position: fixed;
    right: 0;
    width: calc(50% - 40px);
    z-index: 2;
    height: 100%;
    overflow-y: auto; */
  }
`;

export const ChecklistPageContainer = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}px) {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
  }
`;
