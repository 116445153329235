import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ChecklistContext } from "../../context/ChecklistManager";
import AddActivityTemplate from "../../templates/AddActivityTemplate";

export const AddActivityPage = (): JSX.Element => {
  const { checkUid } = useParams<{ checkUid: string }>();
  const checklistContext = useContext(ChecklistContext);
  const checkViewModel = checklistContext.checklist.groups
    .find((s) => s.checks.find((c) => c.uid === checkUid))
    ?.checks.find((c) => c.uid === checkUid);

  return (
    <>
      {checkViewModel && (
        <AddActivityTemplate checkViewModel={checkViewModel} />
      )}
    </>
  );
};
