import { makeObservable, observable } from "mobx";
import { CheckScanEntity } from "../../core/domain/entities/checklist/check/CheckScanEntity";
import { ISetCheckValueScanRequest } from "../../infrastructure/api/endpoints/common/request.types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckScanViewModel extends ICheckBaseViewModel {
  barcode: string | null;

  scan: (data: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckScanViewModel
  extends CheckBaseViewModel
  implements ICheckScanViewModel
{
  public barcode: string | null = null;

  constructor(check: CheckScanEntity, checklistUid: string, groupUid: string) {
    super(check, checklistUid, groupUid);

    this.barcode = check.barcode;

    this.finished = this.isFinished();

    makeObservable(this, {
      barcode: observable,
    });
  }

  public async scan(data: string): Promise<void> {
    const tempState = this.barcode;

    await this.update<ISetCheckValueScanRequest>(
      {
        barcode: data,
      },
      () => {
        this.barcode = data;
      },
      () => {
        this.barcode = tempState;
      },
    );
  }

  public async reset(): Promise<void> {
    const tempState = this.barcode;

    await this.update<ISetCheckValueScanRequest>(
      {
        barcode: null,
      },
      () => {
        this.barcode = null;
      },
      () => {
        this.barcode = tempState;
      },
    );
  }
}
