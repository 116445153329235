import React, { PropsWithChildren } from "react";
import { GridElement } from "./styled";

interface IContainerProps {
  spacing?: number;
  item?: boolean;
  padded?: boolean;
  padding?: number;
  columns?: number;
}

export const Grid: React.FC<PropsWithChildren<IContainerProps>> = ({
  item = false,
  padded = false,
  padding = 2,
  spacing = 1,
  columns = 1,
  children,
}) => {
  return (
    <GridElement
      padding={padding}
      item={item}
      spacing={spacing}
      padded={padded}
      columns={columns}
    >
      {children}
    </GridElement>
  );
};
