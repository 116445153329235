import React, { PropsWithChildren } from "react";
import { TypographyBodyLargeElement } from "./styled";

interface ITypographyBodyLargeProps {
  lineHeight?: boolean;
}

export const TypographyBodyLarge: React.FC<
  PropsWithChildren<ITypographyBodyLargeProps>
> = ({ lineHeight = true, children }) => {
  return (
    <TypographyBodyLargeElement className="text-base" lineHeight={lineHeight}>
      {children}
    </TypographyBodyLargeElement>
  );
};
