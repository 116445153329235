import React, { PropsWithChildren } from "react";
import { TypographyHeadlineSmallElement } from "./styled";

export const TypographyHeadlineSmall: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TypographyHeadlineSmallElement className="text-2xl">
      {children}
    </TypographyHeadlineSmallElement>
  );
};
