import { mdiChevronRight } from "@mdi/js";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import Grid from "../../atoms/Grid";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import ListButton from "../ListButton";
import {
  ChecklistSectionChecksContainerElement,
  StyledChecklistSectionIconElement,
} from "./styled";

interface IChecklistSectionGroupProps {
  title?: string;
  checks: ICheckBaseViewModel[];
  initialOpenState?: boolean;
}

export const ChecklistSectionGroup: React.FC<
  PropsWithChildren<IChecklistSectionGroupProps>
> = ({ title, checks, initialOpenState = false, children }) => {
  const [open, setOpen] = useState(initialOpenState);
  const { checkUid } = useParams<{ checkUid: string }>();

  useMemo(() => {
    if (checks.find((c) => c.uid === checkUid)) {
      setOpen(true);
    }
  }, [checkUid]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {title && (
        <div style={{ borderBottom: "1px solid #49454F" }}>
          <Grid>
            <ListButton
              title={`${title} (${checks.length})`}
              onClick={toggleOpen}
              onRenderTitle={(title) => (
                <TypographyBodyMedium>{title}</TypographyBodyMedium>
              )}
              quickActionElement={
                <StyledChecklistSectionIconElement
                  open={open}
                  path={mdiChevronRight}
                  size={1}
                />
              }
            />
          </Grid>
        </div>
      )}
      {(open || !title) && (
        <ChecklistSectionChecksContainerElement className="px-2 1border-x-2 1border-b-2 1border-card 1border-x 1border-b rounded-b-lg overflow-hidden">
          {children}
        </ChecklistSectionChecksContainerElement>
      )}
    </>
  );
};
