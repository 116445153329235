import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { ActivityEntity } from "../../domain/entities/checklist/ActivityEntity";
import { IContentResult } from "../../results/Result";

export class CreateCommentCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    checklistUid: string,
    groupUid: string,
    checkUid: string,
    message: string,
  ): Promise<IContentResult<ActivityEntity>> {
    const res = await this._checklistRepository.createComment(
      checklistUid,
      groupUid,
      checkUid,
      message,
    );

    return res;
  }
}
