import styled from "styled-components/macro";

interface IPopupContainerProps {
  open?: boolean;
}

export const Presentation = styled.div<IPopupContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  color: transparent;
  display: ${(props) => (props.open ? "flex" : "none")};
  height: 100vh;
  padding: 25px;
`;

export const ImageContainer = styled.div`
  position: relative;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
`;

export const PopupBackdrop = styled.label`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  background: gray;
  border-radius: 32px;
  position: absolute;
  top: -16px;
  right: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.surface.main};
  color: ${(props) => props.theme.surface.onMain};
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  max-width: 350px;
  max-height: 350px;
  height: 100%;
`;
