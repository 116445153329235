import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckSingleOptionEntity extends ICheckBaseEntity {
  selectedChoice: string | null;
  choices: { [s: string]: string } | null;
}

export class CheckSingleOptionEntity
  extends CheckBaseEntity
  implements ICheckSingleOptionEntity
{
  public readonly selectedChoice: string | null;
  public readonly choices: { [s: string]: string } | null;

  protected constructor(check: ICheckSingleOptionEntity) {
    super(check);

    this.selectedChoice = check.selectedChoice;
    this.choices = check.choices;
  }

  public static new(
    check: Omit<ICheckSingleOptionEntity, "uid">,
  ): CheckSingleOptionEntity {
    return new CheckSingleOptionEntity({ uid: "non-existing", ...check });
  }

  public static existing(
    check: ICheckSingleOptionEntity,
  ): CheckSingleOptionEntity {
    return new CheckSingleOptionEntity(check);
  }
}
