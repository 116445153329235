import styled, { css } from "styled-components/macro";

const topLayerPageLayout = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;

export const PageLayoutElement = styled.div<{ top: boolean }>`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
    ${(props) => (props.top ? topLayerPageLayout : "")}

    @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
      /* margin-top: 84px; */
    }
  }

  /* background: ${(props) => props.theme.background.main}; */
  min-height: 100%;
  height: auto;
  /* padding-bottom: 84px; */
`;

export const PageLayoutHeaderElement = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  /* margin-top: 105px; */
  margin-top: 25px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
    /* margin-top: 64px; */
    margin-top: 12px;
  }
`;

export const PageLayoutBodyElement = styled.div``;

export const NavigationContainerElement = styled.div`
  height: 56px;
  width: 100%;
  top: 0px;
  z-index: 10;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid ${(props) => props.theme.surfaceVariant.main};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
    /* display: none; */
    position: fixed;
  }
`;

export const NavigationInnerElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const NavigationBackElement = styled.div<{ persist: boolean }>`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}px) {
    display: ${(props) => (props.persist ? "block" : "none")};
  }
`;

export const NavigationHamburgerElement = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }
`;
