import styled from "styled-components/macro";

interface ITypographyBodyLargeElementProps {
  lineHeight?: boolean;
}

export const TypographyBodyLargeElement = styled.p<ITypographyBodyLargeElementProps>`
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
`;
