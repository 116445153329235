import styled from "styled-components/macro";

export const ActivitySequenceElement = styled.div`
  /* display: grid; */
  grid-template-rows: auto 1fr;
  grid-row-gap: 12px;
  padding-top: 12px;
  color: ${(props) => props.theme.outline.main};
`;

export const ActivitySequenceLineElement = styled.div`
  width: 1px;
  height: calc(100% + 24px);
  justify-self: center;
  position: relative;
`;

export const ActivitySequenceLineElementSmall = styled.div`
  width: 1px;
  height: 24px;
  justify-self: center;
  position: relative;
`;

export const ActivityElement = styled.div`
  /* display: grid; */
  /* grid-template-columns: auto 1fr; */
  /* grid-column-gap: 16px; */

  &:last-child ${ActivitySequenceLineElement} {
    height: 100%;
  }
`;

export const ThumbnailContainerElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  margin: 12px 0;
`;

export const ThumbnailImageElement = styled.div<{ src: string }>`
  width: 75px;
  height: 75px;
  background: url(${(props) => props.src}) center;
  background-size: cover;
`;
