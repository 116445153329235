import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckChecklistEntity extends ICheckBaseEntity {
  serialNumber: string | null;
}

export class CheckChecklistEntity
  extends CheckBaseEntity
  implements ICheckChecklistEntity
{
  public readonly serialNumber: string | null;

  protected constructor(check: ICheckChecklistEntity) {
    super(check);

    this.serialNumber = check.serialNumber;
  }

  public static new(
    check: Omit<ICheckChecklistEntity, "uid">,
  ): CheckChecklistEntity {
    return new CheckChecklistEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckChecklistEntity): CheckChecklistEntity {
    return new CheckChecklistEntity(check);
  }
}
