import TimeAgo from "javascript-time-ago";
import { useEffect, useState } from "react";

interface IRelativeTimestampProps {
  timestamp: Date;
}

export const RelativeTimestamp = ({
  timestamp,
}: IRelativeTimestampProps): JSX.Element => {
  const timeAgo = new TimeAgo("nl-NL");
  const [dateLabel, setDateLabel] = useState<string>("");
  let timeout: NodeJS.Timeout;
  // `setTimeout()` has a bug where it fires immediately
  // when the interval is longer than about `24.85` days.
  // https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
  const SET_TIMEOUT_MAX_SAFE_INTERVAL = 2147483647;

  const getSafeTimeoutInterval = (interval: number) => {
    return Math.min(interval, SET_TIMEOUT_MAX_SAFE_INTERVAL);
  };

  const renderLabel = () => {
    const [formattedDate, timeToNextUpdate] = timeAgo.format(timestamp, {
      getTimeToNextUpdate: true,
    }) as [string, number | undefined];

    setDateLabel(formattedDate);

    timeout = setTimeout(
      renderLabel,
      getSafeTimeoutInterval(timeToNextUpdate || 60 * 1000),
    );
  };

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    renderLabel();

    return () => {
      clearTimeout(timeout);
    };
  }, [timestamp]);

  return <>{dateLabel}</>;
};
