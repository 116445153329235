import { AlignLeft } from "lucide-react";
import { observer } from "mobx-react-lite";
import { checksHooks } from "../../../api";
import { ICheckSingleOptionViewModel } from "../../../application/viewModels/CheckSingleOptionViewModel";
import { Checkbox } from "../../ui/checkbox";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupYesNoProps {
  check: ICheckSingleOptionViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistUid: string;
  groupUid: string;
}

export const CheckPopupSingleOption = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistUid,
    groupUid,
  }: ICheckPopupYesNoProps): JSX.Element => {
    const { mutate } = checksHooks.usePut(
      "/checklists/groups/checks/single-choice-list/value",
    );
    const { mutate: mutateReset } = checksHooks.usePut(
      "/checklists/groups/checks/single-choice-list/value/reset",
    );

    const handleAnswer = async (option: string) => {
      mutate(
        {
          //TODO-translations: update option to be the selected item key.
          selectedItemKey: option,
          checklistUid,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.answer(option);
            onFinish();
          },
        },
      );
    };

    const handleReset = async () => {
      mutateReset(
        {
          checklistUid,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {!signed && check.selectedChoice === null && (
            <ActionContainer>
              <div className="space-y-2 w-full">
                {/* TODO-translations: fix this... */}
                {Object.keys(check.choices || {})?.map((key, idx) => (
                  <label
                    key={key}
                    htmlFor={key}
                    className="block cursor-pointer hover:bg-surface-high duration-100 ease-out rounded-md border-2"
                  >
                    <span className="flex justify-between py-2 px-3 w-full items-center text-sm font-medium text-muted-foreground">
                      {check && check.choices && check.choices[key]}
                      <Checkbox
                        id={key}
                        onCheckedChange={(incoming) => {
                          handleAnswer(key);
                        }}
                      />
                    </span>
                  </label>
                ))}
              </div>
            </ActionContainer>
          )}

          {check.selectedChoice !== null && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <AlignLeft className="w-5 h-5 mr-2" />
                {check.selectedChoice}
              </AnswerBadge>
            </AnswerContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
