import React, { useState } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import TextareaField from "../../atoms/TextareaField";
import { TextareaContainerElement, TextareaLabelMotionElement } from "./styled";

interface ITextareaProps extends TextareaAutosizeProps {
  label: string;
  onChangeValue: (value: string) => void;
}

const labelAnimationVariants = {
  unfocused: {
    x: 13,
    y: 8,
    scale: 1,
    transition: { type: "tween", ease: [0, 0, 0.2, 1], duration: 0.2 },
  },
  focused: {
    x: 13,
    y: -19,
    scale: 0.75,
    transition: { type: "tween", ease: [0, 0, 0.2, 1], duration: 0.2 },
  },
};

export const Textarea = ({
  label,
  onChangeValue,
  ...props
}: ITextareaProps): JSX.Element => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChangeValue(e.target.value);
  };

  return (
    <TextareaContainerElement disabled={props.disabled}>
      <TextareaLabelMotionElement
        className="bg-surface-container"
        variants={labelAnimationVariants}
        animate={focus || value ? "focused" : "unfocused"}
      >
        {label}
      </TextareaLabelMotionElement>
      <TextareaField
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={handleChange}
        {...props}
      />
    </TextareaContainerElement>
  );
};
