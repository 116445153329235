import { Loader2, QrCode } from "lucide-react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ICheckScanViewModel } from "../../../application/viewModels/CheckScanViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import { useScanner } from "../../hooks/useMemor10Scanner";
import ScannerPopup from "../../molecules/ScannerPopup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupScanProps {
  check: ICheckScanViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupScan = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
  }: ICheckPopupScanProps): JSX.Element => {
    const {
      scan,
      scanResult,
      scanMethod,
      reset,
      overrideScanMethod,
      setDefaultScanMethod,
      resetCamera,
      scanning,
    } = useScanner("checkPopupScanReader");
    const [scanActive, setScanActive] = useState(false);
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleScannerScan = async () => {
      if (scanMethod === "text") {
        setScanActive(true);
        return;
      }

      if (scanMethod === "camera") {
        setScanActive(true);
      }

      try {
        await scan();
      } catch (e: unknown) {
        const { message } = e as Error;

        alert(`${message}`);
      }
    };

    const handleScan = async (data: string) => {
      await check.scan(data);

      resetCamera();

      if (check.finished) {
        onFinish();
      }
    };

    const handleReset = () => {
      reset();
      check.reset();
    };

    const handleCloseDialog = () => {
      setScanActive(false);

      setDefaultScanMethod();
    };

    React.useEffect(() => {
      (async () => {
        if (scanResult && scanMethod === "memor10") {
          await check.scan(scanResult);

          if (check.finished) {
            onFinish();
          }
        }
      })();
    }, [scanResult]);

    const { t } = useTranslation();

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.barcode !== null && (
            <AnswerContainer>
              {check.state === CheckState.Bad && check.barcode !== null ? (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel={t("inspectionPage.checkPopup.scanCheck.retry")}
                  onReset={handleReset}
                >
                  <QrCode className="w-5 h-5 mr-2" />({check.barcode}){" "}
                  {t("inspectionPage.checkPopup.scanCheck.invalidInput")}
                </AnswerBadge>
              ) : (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <QrCode className="w-5 h-5 mr-2" />
                  {check.barcode}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          {scanMethod === "camera" && (
            <ScannerPopup
              readerId={"checkPopupScanReader"}
              onManualInput={() => overrideScanMethod("text")}
              onDeleteResult={reset}
              open={scanActive}
              data={scanResult}
              onConfirm={() => {
                setScanActive(false);
                handleScan(scanResult ?? "");
              }}
              onClose={() => {
                resetCamera();
                setScanActive(false);
              }}
            />
          )}
          <Dialog
            open={scanActive && scanMethod === "text"}
            onOpenChange={(incoming) => {
              setScanActive(incoming);

              if (!incoming) {
                handleCloseDialog();
              }
            }}
          >
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.scanCheck.enterCode")}
                </DialogTitle>
                <DialogDescription>{check.title}</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleScan(value);

                    setValue(undefined);

                    handleCloseDialog();
                  }}
                >
                  <Input
                    autoFocus
                    className="w-full"
                    placeholder={t(
                      "inspectionPage.checkPopup.scanCheck.enterCode",
                    )}
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </form>
              </div>
              <DialogFooter></DialogFooter>
            </DialogContent>

            {!signed && !check.finished && check.barcode === null && (
              <DialogTrigger asChild>
                <ActionContainer>
                  <PrimaryActionButton
                    disabled={scanning}
                    onClick={handleScannerScan}
                  >
                    {scanning && (
                      <Loader2 className="animate-spin w-4 h-4 mr-2" />
                    )}
                    {scanMethod === "text"
                      ? t("inspectionPage.checkPopup.scanCheck.scanButtonText")
                      : t("inspectionPage.checkPopup.scanCheck.scanButtonScan")}
                  </PrimaryActionButton>
                </ActionContainer>
              </DialogTrigger>
            )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
