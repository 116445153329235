import React from "react";
import { SignIn } from "@clerk/clerk-react";
import LogoSvg from "../../general/App/logo-svg";

export const SignInPage: React.FC = () => {
  return (
    <div className="items-center flex flex-col lg:mt-20 mt-6">
      <div className="w-fit">
        <div className="px-6 hidden">
          <div className="p-1 flex items-center rounded-full w-full h-12 bg-neutral-200/75 mb-4">
            <div
              style={{ borderRadius: "24px 8px 8px 24px" }}
              className="flex items-center justify-center bg-white h-full rounded-l-full w-full cursor-pointer"
            >
              <span className="text-sm font-semibold text-neutral-900">
                Operations
              </span>
            </div>
            <div className="flex items-center justify-center h-full rounded-lg w-full cursor-pointer group">
              <span className="text-sm font-semibold text-neutral-500 group-hover:text-neutral-900">
                Management
              </span>
            </div>
          </div>
        </div>
        <SignIn
          path={"/sign-in"}
          appearance={{
            elements: {
              card: "bg-card border-[hsl(var(--border))] border shadow-none",
              formButtonPrimary:
                "bg-primary text-primary-foreground hover:bg-primary/90",
              dividerText: "text-muted-foreground",
              socialButtonsBlockButton:
                "border-[hsl(var(--border1))] border bg-body-background",
              headerTitle: "text-primary",
              headerSubtitle: "text-muted-foreground",
              socialButtonsBlockButtonArrow: "text-muted-foreground",
              socialButtonsBlockButtonText: "text-primary",
              dividerLine: "bg-accent",
              footerActionLink: "text-primary hover:text-primary",
              formFieldLabel: "text-primary",
              footerActionText: "text-muted-foreground",
              alertText: "text-primary",
              formFieldInput:
                "flex h-10 w-full rounded-md border border-input 1bg-transparent bg-body-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            },
          }}
        />
        <div className="text-primary left-0 w-full flex gap-x-2 mt-4 items-center justify-between px-6">
          <a
            className="text-xs font-semibold hover:underline flex items-center gap-x-1"
            href={"https://disc.dev"}
            target="_blank"
            rel="noreferrer"
          >
            <LogoSvg width={12} />
            disc.dev
          </a>
          <div className="flex gap-x-2 items-center">
            <a
              className="text-xs font-semibold hover:underline flex items-center gap-x-1"
              href={"https://checklab.nl/privacy-declaration"}
              target="_blank"
              rel="noreferrer"
            >
              Privacverklaring
            </a>
            <span className="w-1 h-1 bg-neutral-400/75 rounded-full"></span>
            <a
              className="text-xs font-semibold hover:underline flex items-center gap-x-1"
              href={"https://support.disc.dev"}
              target="_blank"
              rel="noreferrer"
            >
              Ondersteuning
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
