import { useWindowSize } from "@react-hook/window-size";
import { File } from "lucide-react";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import { templateWorkInstructionsHooks } from "../../../api";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { OperatorRoutePath } from "../../../data";
import Grid from "../../atoms/Grid";
import { useCheck } from "../../hooks/useCheck";
import ImagePopup from "../../molecules/ImagePopup";
import { ResponsiveImage } from "../../molecules/ImagePopup/styled";
import PageLayout from "../../molecules/PageLayout";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const InstructionTemplate: React.FC<{
  checkViewModel: ICheckBaseViewModel;
}> = ({ checkViewModel }) => {
  const { serialNumber, checkUid, type } = useParams<{
    serialNumber: string;
    checkUid: string;
    type: string;
  }>();
  const check = useCheck(checkViewModel);

  const [photoPopup, setPhotoPopup] = useState("");

  const handlePhotoClick = (uri: string) => {
    setPhotoPopup(uri);
  };

  const { data: instruction } = templateWorkInstructionsHooks.useGet(
    "/workinstructions/:workInstructionId",
    {
      params: {
        workInstructionId: Number(checkViewModel.instructionId),
      },
    },
  );

  return (
    <PageLayout
      top
      title="Instructies"
      subTitle={check.title}
      prevRoute={`/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${OperatorRoutePath.CheckBase}/${checkUid}`}
      showBackButton
    >
      <Grid>
        {instruction && (
          <div>
            <p className="text-lg">{instruction.title}</p>
            <p className="text-base text-muted-foreground">
              {instruction.description}
            </p>
            <div className="mt-2">
              {instruction.files?.map((file) => (
                <PdfFile key={file.id} file={file} />
              ))}
            </div>
          </div>
        )}
      </Grid>
      <ImagePopup
        onClose={() => {
          setPhotoPopup("");
        }}
        open={!!photoPopup}
      >
        <ResponsiveImage src={photoPopup} />
      </ImagePopup>
    </PageLayout>
  );
};

function PdfFile({ file }: { file: any }) {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [width] = useWindowSize();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      onOpenChange={(incoming) => {
        if (!incoming) {
          setPageNumber(1);
        }
      }}
    >
      <DialogTrigger asChild>
        <div
          className="p-2 mb-2 rounded-md border cursor-pointer flex items-center"
          key={file.id}
        >
          <File className="w-4 h-4 mr-2" />

          <div>
            <p className="text-sm">{file.title}</p>
            <p className="text-blue-400 text-xs">{file.fileName}</p>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[540px]">
        <DialogHeader>
          <DialogTitle>{file.title}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div>
            <Document file={file.uri} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                width={width < 640 ? width - 48 : 540 - 48}
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </div>
        </div>
        <DialogFooter>
          <div className="flex items-center w-full justify-center gap-x-4">
            <Button
              variant={"outline"}
              onClick={() => setPageNumber((v) => (v > 1 ? v - 1 : v))}
            >
              {"Vorige"}
            </Button>
            <span className="text-sm">
              {pageNumber} van {numPages}
            </span>
            <Button
              variant={"outline"}
              onClick={() => setPageNumber((v) => (v < numPages ? v + 1 : v))}
            >
              {"Volgende"}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
