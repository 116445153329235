import React, { PropsWithChildren } from "react";
import { ButtonElement } from "./styled";
import TypographyLabelLarge from "../../atoms/TypographyLabelLarge";
import { DefaultTheme } from "styled-components";

export enum ButtonVariant {
  filled = "filled",
  outlined = "outlined",
  card = "card",
  text = "text",
}

export type ButtonColor = Extract<keyof DefaultTheme, "primary" | "secondary">;

interface IButtonProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button: React.FC<PropsWithChildren<IButtonProps>> = ({
  variant = ButtonVariant.filled,
  color = "primary",
  children,
  disabled = false,
  onClick,
}) => {
  return (
    <ButtonElement
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled}
      className="w-full"
    >
      <TypographyLabelLarge>{children}</TypographyLabelLarge>
    </ButtonElement>
  );
};
