import { makeAutoObservable } from "mobx";
import { SectionEntity } from "../../core/domain/entities/checklist/SectionEntity";
import { CheckChecklistEntity } from "../../core/domain/entities/checklist/check/CheckChecklistEntity";
import { CheckInputNumberEntity } from "../../core/domain/entities/checklist/check/CheckInputNumberEntity";
import { CheckInputSingleLineEntity } from "../../core/domain/entities/checklist/check/CheckInputSingleLineEntity";
import { CheckOkNotOkEntity } from "../../core/domain/entities/checklist/check/CheckOkNotOkEntity";
import { CheckPhotoEntity } from "../../core/domain/entities/checklist/check/CheckPhotoEntity";
import { CheckScanEntity } from "../../core/domain/entities/checklist/check/CheckScanEntity";
import { CheckSignatureEntity } from "../../core/domain/entities/checklist/check/CheckSignatureEntity";
import { CheckSingleOptionEntity } from "../../core/domain/entities/checklist/check/CheckSingleOptionEntity";
import { CheckYesNoEntity } from "../../core/domain/entities/checklist/check/CheckYesNoEntity";
import { ICheckBaseViewModel } from "./CheckBaseViewModel";
import { CheckChecklistViewModel } from "./CheckChecklistViewModel";
import { CheckInputNumberViewModel } from "./CheckInputNumberViewModel";
import { CheckInputSingleLineViewModel } from "./CheckInputSingleLineViewModel";
import { CheckOkNotOkViewModel, CheckTypes } from "./CheckOkNotOkViewModel";
import { CheckPhotoViewModel } from "./CheckPhotoViewModel";
import { CheckScanViewModel } from "./CheckScanViewModel";
import { CheckSignatureViewModel } from "./CheckSignatureViewModel";
import { CheckSingleOptionViewModel } from "./CheckSingleOptionViewModel";
import { CheckYesNoViewModel } from "./CheckYesNoViewModel";

export interface IChecklistSectionViewModel {
  uid: string;
  title: string;
  checks: ICheckBaseViewModel[];
  unfilteredChecks: ICheckBaseViewModel[];
  percentage: number;
}

export class SectionViewModel implements IChecklistSectionViewModel {
  public uid: string = "";
  public title: string = "";

  private readonly _checklistUid: string;
  private readonly _checks: ICheckBaseViewModel[] = [];

  constructor(group: SectionEntity, checklistUid: string) {
    this.uid = group.uid;
    this.title = group.title;
    this._checks = group.checks.map((c) => {
      switch (c.type) {
        case CheckTypes.yesNo:
          return new CheckYesNoViewModel(
            c as CheckYesNoEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.scan:
          return new CheckScanViewModel(
            c as CheckScanEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.checklist:
          return new CheckChecklistViewModel(
            c as CheckChecklistEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.inputSingleLine:
          return new CheckInputSingleLineViewModel(
            c as CheckInputSingleLineEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.inputNumber:
          return new CheckInputNumberViewModel(
            c as CheckInputNumberEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.signature:
          return new CheckSignatureViewModel(
            c as CheckSignatureEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.singleOption:
          return new CheckSingleOptionViewModel(
            c as CheckSingleOptionEntity,
            checklistUid,
            group.uid,
          );
        case CheckTypes.photo:
          return new CheckPhotoViewModel(
            c as CheckPhotoEntity,
            checklistUid,
            group.uid,
          );
        default:
          return new CheckOkNotOkViewModel(
            c as CheckOkNotOkEntity,
            checklistUid,
            group.uid,
          );
      }
    });
    this._checklistUid = checklistUid;

    makeAutoObservable(this);
  }

  public get percentage(): number {
    return Math.round(
      (this.checks.filter((c) => c.finished && c.required).length /
        this.checks.length) *
        100,
    );
  }

  public get checks(): ICheckBaseViewModel[] {
    return this._checks;
  }

  public get unfilteredChecks(): ICheckBaseViewModel[] {
    return this._checks;
  }
}
