import React, { FocusEventHandler } from "react";
import { TextFieldElement } from "./styled";

interface ITextFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  value?: string;
}

export const TextField = ({
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  value,
}: ITextFieldProps): JSX.Element => {
  return (
    <TextFieldElement
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={"text"}
      value={value}
      onKeyDown={onKeyDown}
    />
  );
};
