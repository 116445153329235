import { mdiArrowRight, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { motion, useAnimation } from "framer-motion";
import {
  ArrowLeft,
  ChevronRight,
  FolderOpen,
  HelpCircle,
  PackagePlus,
  Search,
  Settings,
} from "lucide-react";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import Grid from "../../atoms/Grid";
import { Logo } from "../../atoms/Logo/Logo";
import { ChecklistContext } from "../../context/ChecklistManager";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
  NavigationBackElement,
  NavigationContainerElement,
  NavigationHamburgerElement,
  NavigationInnerElement,
  PageLayoutElement,
  PageLayoutHeaderElement,
} from "./styled";

interface IPageLayoutProps {
  title?: string;
  subTitle?: string;
  prevRoute?: string;
  transformOriginY?: number;
  top?: boolean;
  showBackButton?: boolean;
  showBreadCrumbsDesktop?: boolean;
  persistentRoute?: string;
}

interface ILocationSate {
  isPreviousPage?: boolean;
  animate?: boolean;
}

export const PageLayout: React.FC<PropsWithChildren<IPageLayoutProps>> = ({
  title,
  subTitle,
  children,
  prevRoute = "/",
  transformOriginY = 175,
  top = false,
  showBackButton = false,
  showBreadCrumbsDesktop = false,
  persistentRoute,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isPreviousPage = false, animate = true } =
    (location.state as ILocationSate) ?? {};

  const animationPrevious = {
    initial: {
      opacity: 0,
      scale: 1.1,
      transformOrigin: `center ${transformOriginY}px`,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.15, ease: "easeOut" },
    },
  };

  const animationNext = {
    initial: {
      opacity: 0,
      scale: 0.9,
      transformOrigin: `center ${transformOriginY}px`,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.15, ease: "easeOut" },
    },
  };

  const [route] = useState(persistentRoute ?? location.pathname);
  const animationControl = useAnimation();
  const theme = useTheme();
  const mediumUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  useEffect(() => {
    if (!animate && !persistentRoute) {
      animationControl.set(animationPrevious.animate);

      return;
    }
    animationControl.start("animate");
    // Reset page animation when another page is opened on top of current page.
    // if (route !== location.pathname && !mediumUp) {
    //   animationControl.set(animationPrevious.initial);
    // } else {
    //   animationControl.start("animate");
    // }
  }, [location.pathname]);

  const checklistContext = useContext(ChecklistContext);
  const checklist = checklistContext ? checklistContext.checklist : undefined;
  const { checkUid } = useParams<{ checkUid: string }>();

  return (
    <PageLayoutElement className="bg-surface-container" top={top}>
      <motion.div
        variants={isPreviousPage ? animationPrevious : animationNext}
        initial="initial"
        animate={animationControl}
        style={{ height: "100%" }}
      >
        <NavigationContainerElement className="hidden bg-background">
          <NavigationInnerElement>
            {location.pathname === "/" && (
              <div>
                <Logo height={32} />
              </div>
            )}
            <NavigationBackElement persist={showBackButton}>
              {window.location.pathname !== "/" && (
                <div
                  onClick={() => {
                    if (prevRoute === "/") {
                      navigate(-1);
                    } else {
                      navigate(prevRoute, {
                        state: { isPreviousPage: true, animate: true },
                      });
                    }
                  }}
                >
                  <Icon path={mdiArrowRight} size={1} horizontal />
                </div>
              )}
            </NavigationBackElement>
            {checklistContext && (
              <div
                className={`${
                  showBreadCrumbsDesktop ? "flex" : "lg:hidden"
                } w-full `}
              >
                {checklist && checklist.title && checkUid && (
                  <span
                    className={`flex w-full text-left ml-3 font-medium items-center`}
                  >
                    <span className="opacity-50 inline-block max-w-[80px] overflow-hidden text-ellipsis">
                      {checklist.title}
                    </span>
                    <span className="opacity-50 inline-block mx-1">/</span>
                    <span className="inline-block max-w-[120px] overflow-hidden text-ellipsis mr-1">
                      {
                        checklist.groups.find((s) =>
                          s.checks.find((c) => c.uid === checkUid),
                        )?.title
                      }
                    </span>
                    <span className="inline-block w-fit">
                      (
                      {1 +
                        (checklist.groups
                          .find((s) => s.checks.find((c) => c.uid === checkUid))
                          ?.checks.findIndex((c) => c.uid === checkUid) ?? 0)}
                      /
                      {
                        checklist.groups.find((s) =>
                          s.checks.find((c) => c.uid === checkUid),
                        )?.checks.length
                      }
                      )
                    </span>
                  </span>
                )}

                {checklist && checklist.title && !checkUid && (
                  <span className="w-full text-left ml-3 font-medium flex items-center">
                    <span className="opacity-50 inline-block">Checklist</span>
                    <span className="opacity-50 inline-block mx-1">/</span>
                    <span className="inline-block max-w-[100px] overflow-hidden text-ellipsis">
                      {checklist.title}
                    </span>
                  </span>
                )}
              </div>
            )}
            <NavigationHamburgerElement>
              {window.location.pathname !== "/settings" && (
                <div
                  onClick={() => {
                    navigate("/settings", {
                      state: {
                        isPreviousPage: false,
                        previousRoute: location.pathname,
                      },
                    });
                  }}
                >
                  <Icon path={mdiMenu} size={1} horizontal />
                </div>
              )}
            </NavigationHamburgerElement>
          </NavigationInnerElement>
        </NavigationContainerElement>

        <Grid item spacing={0} padded={false}>
          {title !== undefined && (
            <Grid spacing={6}>
              <Grid spacing={2} item>
                <PageLayoutHeaderElement>
                  <div className="flex items-center mb-1.5">
                    <ArrowLeft
                      className="w-6 h-6 text-muted-foreground mr-2"
                      onClick={() => navigate(-1)}
                    />
                    <span className="text-3xl leading-none">{title}</span>
                  </div>

                  {subTitle && (
                    <span className="text-base text-muted-foreground block mb-2">
                      {subTitle}
                    </span>
                  )}
                </PageLayoutHeaderElement>
              </Grid>
            </Grid>
          )}
          <div>{children}</div>
        </Grid>
      </motion.div>
      <div className="hidden grid grid-cols-3 fixed z-30 w-full bg-transparent sm:bg-background 1border-t bottom-0 left-0 1py-4 items-center px-2 1bg-background 1border-t">
        <div className="z-40 sm:relative fixed sm:block left-0 px-2 sm:px-0 w-full sm:justify-start justify-center bottom-14 sm:bottom-0">
          <div className="cursor-pointer border sm:border-0 px-1.5 py-1.5 sm:max-w-xs rounded-xl flex 1bg-slate-700 1bg-blue-600 bg-[#cac4d0] 1border 1bg-background bg-card 1bg-card/50 backdrop-blur-lg 1border w-full items-center shadow-lg">
            <div className="bg-neutral-400/20 1border w-8 h-8 rounded-lg mr-2 flex items-center justify-center">
              <FolderOpen className="w-4 h-4 text-white/70" />
            </div>
            <div>
              <span className="leading-none text-sm font-semibold block">
                20230505017
              </span>
              <div className="flex items-center mt-1">
                <span className="leading-none text-xs block text-white/60">
                  Project
                </span>
                <div className="mx-1.5 h-1 w-1 rounded-full bg-white/30"></div>
                <span className="leading-none text-xs block text-white/60">
                  1 inspecties
                </span>
              </div>
            </div>
            <div className="ml-auto">
              <div className="flex items-center gap-x-4">
                <ChevronRight className="w-6 h-6 text-white/60" />
              </div>
            </div>
          </div>
        </div>
        <div className="z-30 sm:col-span-1 col-span-3 w-full justify-center flex bg-transparent sm:bg-background 1border-t bottom-0 left-0 py-4 items-center px-4 1bg-background 1border-t">
          <div
            className="block sm:hidden"
            style={{
              background: "linear-gradient(0deg, #1c1b1e, transparent)",
              position: "fixed",
              bottom: 0,
              width: "100%",
              height: "100px",
              left: 0,
              borderBottom: "#1C1B1D solid 20px",
            }}
          ></div>
          <div className="flex gap-x-20 sm:gap-x-12 lg:gap-x-20 z-10 relative">
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => navigate("/")}
            >
              <Search className="w-5.5 h-5.5" />
            </div>
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => navigate("/product-checklist")}
            >
              <PackagePlus className="w-5.5 h-5.5" />
            </div>
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              <Settings className="w-5.5 h-5.5" />
            </div>
          </div>
        </div>
        <div className="z-30 hidden sm:flex w-full justify-end bg-transparent sm:bg-background 1border-t bottom-0 left-0 py-4 items-center px-4 1bg-background 1border-t">
          <div className="flex gap-x-6 z-10 relative">
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              <HelpCircle className="w-5.5 h-5.5 text-muted-foreground" />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </PageLayoutElement>
  );
};
