import styled from "styled-components/macro";

interface ICheckElementProps {
  finished: boolean;
  active?: boolean;
}

export const CheckElement = styled.button<ICheckElementProps>`
  /* padding: 0 16px; */
  display: grid;
  /* grid-template-columns: 1fr auto auto; */
  align-items: flex-start;
  /* border-bottom: 1px dashed ${(props) => props.theme.surfaceVariant.main}; */
  transition: 0.2s ease-out;

  &:last-child {
    /* border-bottom: 2px solid ${(props) =>
      props.theme.surfaceVariant.main}; */
    /* border-bottom: none; */
  }
`;

export const CheckWrapperElement = styled.div<ICheckElementProps>`
  opacity: ${(props) => (props.finished ? 0.55 : 1)};
`;

export const QuickActionElement = styled.div`
  position: relative;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #27f4b1;
    position: absolute;
    right: 2px;
    bottom: 4px;
    border-radius: 8px;
  }
`;

export const FinishedIconWrapper = styled.div`
  /* color: ${(props) => props.theme.success.main}; */
  align-self: flex-start;
  /* padding-top: 14px; */
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  grid-column-gap: 12px;
`;

export const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 6px;
`;

export const UnfinishedIconWrapper = styled.div`
  /* color: ${(props) => props.theme.outline.main}; */
  align-self: flex-start;
  /* padding-top: 14px; */
  position: relative;
`;
