import styled, { css } from "styled-components/macro";

export const CheckStepperElement = styled.div`
  width: 100%;
  height: 34px;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CheckStepperContainerElement = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  grid-template-columns: repeat(9, 100px);
  padding-left: calc(50% - 90px);
  padding-right: calc(50% - 90px);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    /* background: ${(props) => props.theme.success.main}; */
    /* border-bottom: 1px solid ${(props) =>
      props.theme.surfaceVariant.main}; */
    font-weight: 500;
    width: 100%;
    height: 1px;
    opacity: 0.4;
    background: ${(props) => props.theme.surfaceVariant.main};
  }
`;

const activeVariant = css`
  border: 2px solid ${(props) => props.theme.primary.main};
  border-radius: 50px;
`;

export const ChecksStepperIconWrapperElement = styled.div<{
  done: boolean;
  active: boolean;
}>`
  z-index: 1;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.done
      ? props.theme.success.main
      : props.active
      ? props.theme.surfaceVariant.main
      : props.theme.surfaceVariant.main};
  line-height: 0;
  ${(props) => (props.active ? activeVariant : null)}
`;

export const ChecksStepperLineElement = styled.div`
  width: 75px;
  /* border-top: 1px solid ${(props) => props.theme.surfaceVariant.main}; */
`;
