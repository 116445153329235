import React, {PropsWithChildren} from "react";
import {
  CloseButton,
  ImageContainer,
  PopupBackdrop,
  Presentation,
} from "./styled";
import { Portal } from "react-portal";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

interface IImagePopupProps {
  open?: boolean;
  onClose: () => void;
}

export const ImagePopup: React.FC<PropsWithChildren<IImagePopupProps>> = ({
  open,
  onClose,
  children,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Portal>
      {open && (
        <Presentation open={open}>
          <PopupBackdrop onClick={handleClose} />
          <ImageContainer>
            <CloseButton onClick={handleClose}>
              <Icon path={mdiClose} />
            </CloseButton>
            {children}
          </ImageContainer>
        </Presentation>
      )}
    </Portal>
  );
};
