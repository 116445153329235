import { getToken } from "../infrastructure/services/authentication/AuthenticationService";

export default async function handle({
  checklistUid,
  groupUid,
  checkUid,
  commentUid,
}: {
  checklistUid: string;
  groupUid: string;
  checkUid: string;
  commentUid: string;
}): Promise<void> {
  const tokenResult = await getToken();

  if (!tokenResult.isSuccessful) throw Error("Could not get access token.");

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/checklists/${checklistUid}/groups/${groupUid}/checks/${checkUid}/comments/${commentUid}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenResult.content}`,
      },
    },
  );

  if (!res.ok) throw Error("Something went wrong deleting the comment...");
}
