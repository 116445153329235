import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckInputNumberEntity extends ICheckBaseEntity {
  lowerFailureThreshold?: number;
  lowerToleranceThreshold?: number;
  upperFailureThreshold?: number;
  upperToleranceThreshold?: number;

  number: number | null;
}

export class CheckInputNumberEntity
  extends CheckBaseEntity
  implements ICheckInputNumberEntity
{
  public readonly lowerFailureThreshold?: number;
  public readonly lowerToleranceThreshold?: number;
  public readonly upperFailureThreshold?: number;
  public readonly upperToleranceThreshold?: number;

  public readonly number: number | null;

  protected constructor(check: ICheckInputNumberEntity) {
    super(check);

    this.lowerFailureThreshold = check.lowerFailureThreshold;
    this.lowerToleranceThreshold = check.lowerToleranceThreshold;
    this.upperFailureThreshold = check.upperFailureThreshold;
    this.upperToleranceThreshold = check.upperToleranceThreshold;

    this.number = check.number;
  }

  public static new(
    check: Omit<ICheckInputNumberEntity, "uid">,
  ): CheckInputNumberEntity {
    return new CheckInputNumberEntity({ uid: "non-existing", ...check });
  }

  public static existing(
    check: ICheckInputNumberEntity,
  ): CheckInputNumberEntity {
    return new CheckInputNumberEntity(check);
  }
}
