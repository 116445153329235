import { Entity } from "../Entity";

export class PhotoEntity extends Entity {
  private _photoUri: string;

  protected constructor(uid: string, photoUri: string) {
    super(uid);

    this._photoUri = photoUri;
  }

  public get photoUri() {
    return this._photoUri;
  }

  public static new(photoUri: string) {
    return new PhotoEntity("non-existing", photoUri);
  }

  public static existing(uid: string, photoUri: string) {
    return new PhotoEntity(uid, photoUri);
  }
}
