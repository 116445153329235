import { ActivityEntity } from "../../../core/domain/entities/checklist/ActivityEntity";
import { ICommentDTO } from "../common/types";
import { PhotoMapper } from "./PhotoMapper";

export class CommentMapper {
  public static fromDTO(dto: ICommentDTO): ActivityEntity {
    return ActivityEntity.existing({
      uid: dto.uid,
      message: dto.message,
      author: dto.author,
      commentType: dto.type,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
      photos: dto.photos.map((p) => PhotoMapper.fromDTO(p)),
    });
  }
}
