import styled from "styled-components/macro";

interface IGridElementProps {
  spacing: number;
  item: boolean;
  padded: boolean;
  padding: number;
  columns: number;
}

export const GridElement = styled.div<IGridElementProps>`
  padding: ${(props) =>
      props.padded ? (props.padding ? `${12 * props.padding}px` : "24px") : "0"}
    ${(props) =>
      props.item
        ? "0"
        : `${props.padding ? `${12 * props.padding}px` : "24px"}`};
  display: grid;
  grid-row-gap: ${(props) => 12 * props.spacing}px;
  grid-column-gap: ${(props) => 12 * props.spacing}px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;
