import React, { PropsWithChildren } from "react";
import { TypographyTitleMediumElement } from "./styled";

export const TypographyTitleMedium: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TypographyTitleMediumElement className="text-base">
      {children}
    </TypographyTitleMediumElement>
  );
};
