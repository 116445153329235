import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";
import { ISignChecklistRequest } from "../../../infrastructure/api/endpoints/common/request.types";

export class SignChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(request: ISignChecklistRequest): Promise<IResult> {
    // return await

    const res = await this._checklistRepository.signChecklist(request);

    return new Promise((r) => {
      setTimeout(() => {
        r(res);
      }, 2000);
    });
  }
}
