import React, { PropsWithChildren } from "react";
import { TypographyLabelLargeElement } from "./styled";

interface ITypographyLabelLargeProps {
  lineHeight?: boolean;
}

export const TypographyLabelLarge: React.FC<
  PropsWithChildren<ITypographyLabelLargeProps>
> = ({ lineHeight = true, children }) => {
  return (
    <TypographyLabelLargeElement className="text-sm" lineHeight={lineHeight}>
      {children}
    </TypographyLabelLargeElement>
  );
};
