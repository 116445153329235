import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ChecklistContext } from "../../context/ChecklistManager";
import InstructionTemplate from "../../templates/InstructionTemplate";

export const InstructionPage = (): JSX.Element => {
  const { checkUid } = useParams<{ checkUid: string }>();
  const checklistContext = useContext(ChecklistContext);
  const checkViewModel = checklistContext.checklist.groups
    .find((s) => s.checks.find((c) => c.uid === checkUid))
    ?.checks.find((c) => c.uid === checkUid);

  // useEffect(() => {
  //   (async () => {
  //     if (checkViewModel) {
  //       await checkViewModel.setWorkInstruction();
  //     }
  //   })();
  // }, [checkViewModel]);

  return (
    <div className="h-[calc(100vh-16px)] rounded-xl overflow-hidden">
      {checkViewModel && (
        <InstructionTemplate checkViewModel={checkViewModel} />
      )}
    </div>
  );
};
