import { SignUp } from "@clerk/clerk-react";
import LogoSvg from "../../general/App/logo-svg";
import React from "react";
export const SignUpPage: React.FC = () => {
  return (
    <div className="items-center flex flex-col lg:mt-20 mt-6">
      <div className="w-fit">
        <SignUp/>

        <div className="text-neutral-600 left-0 w-full flex gap-x-2 mt-4 items-center justify-between px-6">
          <a
            className="text-xs font-semibold hover:underline flex items-center gap-x-1"
            href={"https://disc.dev"}
            target="_blank"
            rel="noreferrer"
          >
            <LogoSvg/>
            disc.dev
          </a>
          <div className="flex gap-x-2 items-center">
            <a
              className="text-xs font-semibold hover:underline flex items-center gap-x-1"
              href={"https://checklab.nl/privacy-declaration"}
              target="_blank"
              rel="noreferrer"
            >
              Privacverklaring
            </a>
            <span className="w-1 h-1 bg-neutral-400/75 rounded-full"></span>
            <a
              className="text-xs font-semibold hover:underline flex items-center gap-x-1"
              href={"https://support.disc.dev"}
              target="_blank"
              rel="noreferrer"
            >
              Ondersteuning
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
