import { PhotoEntity } from "../../../core/domain/entities/checklist/PhotoEntity";
import { IPhotoDTO } from "../common/types";

export class PhotoMapper {
  public static fromDTO(dto: IPhotoDTO): PhotoEntity {
    return PhotoEntity.existing(dto.uid, dto.uri);
  }

  public static fromDTOs(photos: IPhotoDTO[]): PhotoEntity[] {
    return photos.map((x) => PhotoMapper.fromDTO(x));
  }
}
