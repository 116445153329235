import { WorkInstructionEntity } from "../../core/domain/entities/checklist/WorkInstructionEntity";
import { WorkInstructionExampleEntity } from "../../core/domain/entities/checklist/WorkInstructionExampleEntity";

export interface IInstructionViewModel {
  title: string;
  description: string;
  correctExamples: WorkInstructionExampleEntity[];
  wrongExamples: WorkInstructionExampleEntity[];
}

export class InstructionViewModel implements IInstructionViewModel {
  public title: string = "";
  public description: string = "";
  // Todo: make view models for example entities.
  public correctExamples: WorkInstructionExampleEntity[] = [];
  public wrongExamples: WorkInstructionExampleEntity[] = [];

  constructor(instruction: WorkInstructionEntity) {
    this.title = instruction.title;
    this.description = instruction.description;
    this.correctExamples = instruction.correctExamples;
    this.wrongExamples = instruction.wrongExamples;
  }
}
