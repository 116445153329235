import { Loader2, ScanLine } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ICheckChecklistViewModel } from "../../../application/viewModels/CheckChecklistViewModel";
import { useScanner } from "../../hooks/useMemor10Scanner";
import Dialog from "../../molecules/Dialog";
import ScannerPopup from "../../molecules/ScannerPopup";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupScanProps {
  check: ICheckChecklistViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupChecklist = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
  }: ICheckPopupScanProps): JSX.Element => {
    const {
      scan,
      scanResult,
      scanMethod,
      reset,
      overrideScanMethod,
      setDefaultScanMethod,
      resetCamera,
      scanning,
    } = useScanner("checkPopupChecklistReader");
    const [scanActive, setScanActive] = useState(false);

    const handleScannerScan = async () => {
      if (scanMethod === "text") {
        setScanActive(true);
        return;
      }

      if (scanMethod === "camera") {
        setScanActive(true);
      }

      try {
        await scan();
      } catch (e: unknown) {
        const { message } = e as Error;

        alert(message);
      }
    };

    const handleScan = async (data: string) => {
      resetCamera();

      await check.scan(data);

      if (check.finished) {
        onFinish();
      }
    };

    const handleReset = () => {
      reset();
      check.reset();
    };

    const handleCloseDialog = () => {
      setScanActive(false);
      setDefaultScanMethod();
    };

    useEffect(() => {
      (async () => {
        if (scanResult && scanMethod === "memor10") {
          await check.scan(scanResult);

          if (check.finished) {
            onFinish();
          }
        }
      })();
    }, [scanResult]);

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.serialNumber !== null && (
            <AnswerContainer>
              {!check.checklistExists ? (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel="Opnieuw invoeren"
                  onReset={handleReset}
                >
                  <ScanLine className="w-5 h-5 mr-2" />({check.serialNumber})
                  Checklist ({check.serialNumber}) ontbreekt
                </AnswerBadge>
              ) : (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <ScanLine className="w-5 h-5 mr-2" />
                  Checklist ({check.serialNumber}) gevonden
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          {scanMethod === "camera" && (
            <ScannerPopup
              readerId={"checkPopupChecklistReader"}
              onManualInput={() => overrideScanMethod("text")}
              onDeleteResult={reset}
              open={scanActive}
              data={scanResult}
              onConfirm={() => {
                setScanActive(false);
                handleScan(scanResult ?? "");
              }}
              onClose={() => {
                resetCamera();
                setScanActive(false);
              }}
            />
          )}
          {scanMethod === "text" && (
            <Dialog<string>
              open={scanActive}
              title="Checklist koppelen"
              text="Geen scanner of camera gevonden. Vul de code handmatig in. "
              onClose={handleCloseDialog}
              onSubmit={handleScan}
            />
          )}
          {!signed && !check.finished && check.serialNumber === null && (
            <ActionContainer>
              <PrimaryActionButton
                disabled={scanning}
                onClick={handleScannerScan}
              >
                {scanning && <Loader2 className="animate-spin w-4 h-4 mr-2" />}
                {"Code scannen"}
              </PrimaryActionButton>
            </ActionContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
