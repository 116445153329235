import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";

export class JoinChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    id: number,
    updateCallback: (check: any) => void,
  ): Promise<IResult> {
    return await this._checklistRepository.joinChecklist(id, updateCallback);
  }
}
