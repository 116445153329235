import { IWorkInstructionDTO } from "../common/types";
import { WorkInstructionExampleMapper } from "./WorkInstructionExampleMapper";
import { WorkInstructionEntity } from "../../../core/domain/entities/checklist/WorkInstructionEntity";

export class WorkInstructionMapper {
  public static fromDTO(DTO: IWorkInstructionDTO) {
    return WorkInstructionEntity.existing(
      DTO.uid,
      DTO.title,
      DTO.description,
      DTO.correctExamples.map((e) => WorkInstructionExampleMapper.fromDTO(e)),
      DTO.wrongExamples.map((e) => WorkInstructionExampleMapper.fromDTO(e)),
    );
  }
}
