import { motion } from "framer-motion";
import styled, { css } from "styled-components/macro";

interface IDialogElementProps {
  variant?: "regular";
}

export const chipBase = css`
  border-radius: 25px;
`;

export const chipRegularVariant = css`
  ${chipBase};
  background: ${(props) => props.theme.surface.main};
  color: ${(props) => props.theme.surface.onMain};
`;

export const DialogElement = styled(motion.div)<IDialogElementProps>`
  max-width: 312px;
  width: 100%;
  height: auto;
  position: relative;
  ${(props) => props.variant === "regular" && chipRegularVariant}
`;

export const DialogTitleWrapperElement = styled.div<IDialogElementProps>`
  text-align: left;
`;

export const DialogButtonContainerElement = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DialogWrapperElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  // Above all content.
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  padding: 24px;
`;

export const BackdropElement = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  position: absolute;
`;

export const InputElement = styled.input`
  padding: 8px 0;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid ${(props) => props.theme.primary.main};
  font-size: 16px;
  color: ${(props) => props.theme.background.onMain};
`;
