import { motion } from "framer-motion";
import styled from "styled-components/macro";

export const SelectContainerElement = styled.div`
  position: relative;
`;

export const SelectIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
`;

export const SelectLabelMotionElement = styled(motion.label)`
  position: absolute;
  transform: translate(13px, 16px) scale(1);
  font-size: 16px;
  line-height: 23px;
  animation-fill-mode: forwards;
  transform-origin: left;
  padding: 8px;
  pointer-events: none;
`;
