import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { schemas } from "../api/schema";

type Project = z.infer<
  typeof schemas.Checklab_WebApi_Features_Projects_GetById_V1_GetProjectByIdResponse
> | null;

type ProjectStore = {
  selectedProject: Project;
  updateSelectedProject: (project: Project) => void;
};

export const useProjectStore = create<ProjectStore>()(
  persist(
    (set, get) => {
      return {
        selectedProject: null,
        updateSelectedProject: (project: Project) =>
          set({ selectedProject: project }),
      };
    },
    {
      name: "project-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

type User = {
  userId: string;
} | null;

type UserStore = {
  loggedInUser: User;
  updateLoggedInUser: (user: User) => void;
};

export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => {
      return {
        loggedInUser: null,
        updateLoggedInUser: (user: User) => set({ loggedInUser: user }),
      };
    },
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

type ConnectionStatus = "connecting" | "disconnected" | "error" | "connected";

type SignalRStore = {
  connectionStatus: ConnectionStatus;
  updateConnectionStatus: (status: ConnectionStatus) => void;
};

export const useSignalRStore = create<SignalRStore>()(
  persist(
    (set, get) => {
      return {
        connectionStatus: "connecting",
        updateConnectionStatus: (connectionStatus: ConnectionStatus) =>
          set({ connectionStatus }),
      };
    },
    {
      name: "user-storage",
    },
  ),
);
