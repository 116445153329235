import { ArrowRight, ScanLine, Search } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { checklistsHooks, templateChecklistsHooks } from "../../../api";
import { useProjectStore } from "../../../stores/project-store";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import { Input } from "../../ui/input";

export const ProjectChecklistPage: React.FC = () => {
  const { selectedProject } = useProjectStore();
  const [
    checklistTemplateTitleSearchValue,
    setChecklistTemplateTitleSearchValue,
  ] = useState("");
  const [debouncedChecklistTemplateTitleSearchValue] = useDebounce(
    checklistTemplateTitleSearchValue,
    300,
  );
  const { data: templateChecklists, isLoading: isLoadingTemplateChecklists } =
    templateChecklistsHooks.useGet("/templates/checklists", {
      queries: {
        page: 1,
        size: 25,
        title: debouncedChecklistTemplateTitleSearchValue,
      },
    });

  const { mutate: postChecklist } = checklistsHooks.usePost("/checklists");
  const navigate = useNavigate();

  function handleChecklistTemplateClick(templateChecklistId: number) {
    if (!selectedProject) {
      return;
    }

    postChecklist(
      {
        serialNumber: selectedProject?.code ?? "",
        productLabel: "plabel",
        templateChecklistId: templateChecklistId,
        projectId: selectedProject?.id,
      },
      {
        onSuccess: (data) => {
          navigate(`/checklist/${data.checklistUid}/?check-popup=open`);
        },
      },
    );
  }

  return (
    <LayoutV2>
      <div className="pr-2 pt-2">
        <div className="mb-2 py-4 px-4 bg-surface-container rounded-xl overflow-hidden">
          <span className="text-xl px-2 font-medium pb-4 block">
            Checklist toevoegen
          </span>
          <div className="relative w-full flex items-center">
            <Search className="absolute left-5 w-5 h-5 text-primary" />
            <form
              className="w-full"
              onSubmit={(e) => {
                e.preventDefault();
                (document.activeElement as HTMLInputElement).blur();
              }}
            >
              <Input
                autoFocus
                value={checklistTemplateTitleSearchValue}
                onChange={(e) => {
                  setChecklistTemplateTitleSearchValue(e.target.value);
                }}
                className="rounded-xl text-base border-0 border-zinc-700 pl-14 h-12 pr-4 py-0 bg-surface-lowest dark:bg-surface-highest placeholder:text-muted-foreground"
                placeholder="Checklist template zoeken..."
              />
            </form>
            <div className="flex p-2 h-full absolute right-0">
              <button
                className="border rounded-lg h-full px-2 bg-primary text-primary-foreground"
                onClick={() => {
                  //
                }}
              >
                <ScanLine className="w-4 h-4 text-primary-foreground" />
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "calc(100vh - 148px)",
          }}
          className="hide-scrollbar bg-surface-container rounded-xl overflow-auto"
        >
          <div className="px-4 pt-4"></div>
          <div className="space-y-2 px-4">
            {templateChecklists?.results
              ?.filter((t) => t.productLabels?.length === 0)
              .map((t) => (
                <div
                  key={t.id}
                  onClick={() => handleChecklistTemplateClick(t.id ?? 0)}
                  className="cursor-pointer py-3 px-3 rounded-lg bg-surface-high flex w-full"
                >
                  <span className="block font-medium text-sm text-muted-foreground">
                    {t.title}
                  </span>
                  <div className="ml-auto pt-0.5">
                    <ArrowRight className="w-4 h-4 text-muted-foreground" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
};
