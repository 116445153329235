import Icon from "@mdi/react";
import React from "react";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import {
  IconWrapper,
  ListButtonElement,
  QuickActionElement,
  SubtitleWrapper,
} from "./styled";

interface IListButtonProps {
  title: string;
  subTitle?: string | JSX.Element;
  icon?: string;
  classNameIcon?: string;
  onQuickAction?: () => void;
  quickActionElement?: JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLDivElement> | undefined) => void;
  centerAligned?: boolean;
  disabled?: boolean;
  primary?: boolean;
  onRenderTitle?: (title: string) => JSX.Element;
}

export const ListButton: React.FC<IListButtonProps> = ({
  title,
  subTitle,
  icon,
  onQuickAction,
  quickActionElement,
  onClick,
  centerAligned = true,
  disabled = false,
  primary = false,
  onRenderTitle,
  classNameIcon,
}) => {
  return (
    <ListButtonElement
      className="cursor-pointer"
      onClick={onClick}
      centerAligned={centerAligned}
      disabled={disabled}
      hasIcon={!!icon}
      primary={primary}
      // className="bg-neutral-900 rounded-lg px-4 py-4 border border-neutral-800"
    >
      {icon && (
        <div className={classNameIcon}>
          <IconWrapper primary={primary}>
            <Icon path={icon} size={0.8} />
          </IconWrapper>
        </div>
      )}
      <div>
        {onRenderTitle && onRenderTitle(title)}
        {!onRenderTitle && (
          <span
            className="inline-block text-sm w-fit font-medium"
            style={{ wordBreak: "break-word" }}
          >
            {title}
          </span>
        )}
        {subTitle && (
          <SubtitleWrapper className="opacity-60">
            {typeof icon === "string" && (
              <TypographyBodyMedium>{subTitle}</TypographyBodyMedium>
            )}
            {typeof icon !== "string" && subTitle}
          </SubtitleWrapper>
        )}
      </div>
      {quickActionElement && (
        <QuickActionElement onClick={onQuickAction}>
          {quickActionElement}
        </QuickActionElement>
      )}
    </ListButtonElement>
  );
};
