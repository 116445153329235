import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const DrawerElement = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  background: #00000055;
`;

export const DrawerContainerElement = styled(motion.div)`
  //padding-top: calc(100vh - 260px);
  overflow: scroll;
  max-height: 100vh;
  position: relative;
  z-index: 2;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DrawerBodyElement = styled(motion.div)`
  position: relative;
  border-radius: 8px 8px 0 0;
  background: ${(props) => props.theme.surface.main};
  width: 100%;
  min-height: 100vh;
  margin-top: 232px;
`;

export const DrawerHeaderElement = styled.div`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.surface.main};
  border-bottom: 1px solid ${(props) => props.theme.surfaceVariant.main};
  padding: 24px;
  border-radius: 8px 8px 0 0;
`;
