import { useAuth } from "@clerk/clerk-react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { format } from "date-fns";
import { Check, Loader2, Radio } from "lucide-react";
import { useEffect, useState } from "react";
import { iotHooks } from "../../../api";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import {
  useProjectStore,
  useSignalRStore,
} from "../../../stores/project-store";
import { Button } from "../../ui/button";

type IoTMessage = {
  createdAtUtc: string;
  message: string;
  id: string;
};

export default function CheckIoT({
  checklistId,
  checklistUid,
  groupId,
  check,
}: {
  checklistId: number;
  checklistUid: string;
  groupId: string;
  check: ICheckBaseViewModel;
}) {
  const { data: iotActivities } = iotHooks.useGet(
    "/v2/iot-activities/checklist/:checklistId",
    {
      queries: {
        groupId: groupId,
        checkId: check.uid ?? "",
      },
      params: {
        checklistId: checklistId ?? 0,
      },
    },
    {
      enabled: true,
    },
  );
  const { data: devices } = iotHooks.useGet(
    "/v2/iot-devices/by-locations",
    {
      queries: {
        locationIds: check.locations.map((l) => l.id),
      },
    },
    {
      enabled: true,
    },
  );

  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();
  const { mutate: startDevicePost, isLoading } = iotHooks.usePost(
    "/v2/iot-devices/start",
  );
  const [messages, setMessages] = useState<IoTMessage[]>([]);
  const { updateConnectionStatus } = useSignalRStore();

  useEffect(() => {
    if (iotActivities) {
      const mes = iotActivities?.results?.map((m) => {
        return {
          message: m.message ?? "",
          createdAtUtc: m.createdAtUtc ?? "",
          id: `${m.id}`,
        };
      });

      if (mes) {
        setMessages(mes);
      }
    }
  }, [iotActivities]);

  const { getToken } = useAuth();

  useEffect(() => {
    (async () => {
      let connection: HubConnection | undefined = undefined;
      const token = await getToken({ template: "jwt-template-1" });

      connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/hubs/iot`, {
          accessTokenFactory: () => token ?? "",
        })
        .withAutomaticReconnect()
        .build();

      await connection.start();

      updateConnectionStatus("connected");

      connection.onclose((e) => {
        updateConnectionStatus(e ? "error" : "disconnected");
      });

      await connection.invoke(
        "JoinIoTCheck",
        `${checklistId}`,
        groupId,
        check.uid,
      );

      connection.on("AddIoTActivity", (data: IoTMessage) => {
        setMessages((v) => {
          return [
            ...v.filter((x) => x.id !== data.id),
            {
              message: data.message,
              createdAtUtc: data.createdAtUtc,
              id: data.id,
            },
          ];
        });
      });
    })();
  }, []);

  const { selectedProject } = useProjectStore();

  async function startDevice() {
    if (!selectedDeviceId && !isLoading) {
      return;
    }

    //...
    startDevicePost({
      deviceId: selectedDeviceId,
      checklistId: checklistId,
      groupId: groupId,
      checkId: check.uid,
      checkType: check.type,
      payload: [
        {
          key: "ProjectCode",
          value: `${selectedProject?.code}`,
        },
        {
          key: "Aantalplaten",
          value: `${
            selectedProject?.metadata?.find((m) => m.key === "Aantalplaten")
              ?.value
          }`,
        },
        {
          key: "IsResend",
          value: "false",
        },
        // {
        //   key: "File",
        //   value: "File1",
        // },
        // {
        //   key: "File",
        //   value: "File2",
        // },
      ],
    });
  }

  // Todo: unsafe index...

  const inProgess =
    messages.length > 0 &&
    !messages
      .sort(
        (a, b) =>
          new Date(a.createdAtUtc).getTime() -
          new Date(b.createdAtUtc).getTime(),
      )
      [messages.length - 1]?.message.startsWith("IoT message was");

  const rejectedAndNotInProgress =
    messages.length > 0 &&
    messages
      .sort(
        (a, b) =>
          new Date(a.createdAtUtc).getTime() -
          new Date(b.createdAtUtc).getTime(),
      )
      [messages.length - 1]?.message.startsWith("IoT message was") &&
    check.state === CheckState.Bad;

  const done = check.isFinished();

  useEffect(() => {
    if (!done && devices && devices.results?.length === 1) {
      setSelectedDeviceId(devices.results[0].id);
    }
  }, [devices]);

  return (
    <div>
      <div className="px-6">
        <div>
          {!done &&
            devices?.results?.map((device) => (
              <div
                onClick={() => {
                  setSelectedDeviceId((v) =>
                    device.id === v ? undefined : device.id,
                  );
                }}
                className="border bg-background font-medium rounded-md p-2 cursor-pointer text-sm mb-1.5 flex items-center select-none"
                key={device.id}
              >
                <Radio
                  className={`w-4 h-4 mr-1.5 ${
                    selectedDeviceId === device.id
                      ? "text-green-400"
                      : "text-muted-foreground"
                  }`}
                />
                {device.name}
              </div>
            ))}

          <div className="border rounded-md bg-background h-fit relative">
            <div className="h-52 overflow-y-scroll">
              <div className="p-2">
                {messages
                  .sort(
                    (a, b) =>
                      new Date(b.createdAtUtc).getTime() -
                      new Date(a.createdAtUtc).getTime(),
                  )
                  ?.map((m) => (
                    <div
                      className="text-sm font-mono text-muted-foreground grid grid-cols-[auto_1fr]"
                      key={`${m.id}`}
                    >
                      <p className="uppercase">
                        {/* {format(new Date(m.createdAtUtc), "MMM dd HH:mm:ss")}:{" "} */}
                        {format(new Date(m.createdAtUtc), "HH:mm:ss")}:{" "}
                      </p>
                      <p className="pl-1 text-primary">{m.message}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="px-2 py-1 border-t text-sm font-mono flex items-center justify-left text-muted-foreground absolute bottom-0 w-full">
              {!done && (
                <div className="flex items-center">
                  {inProgess && (
                    <div className="w-3 h-3 mr-1">
                      <Loader2 className="w-3 h-3 animate-[spin_1s_linear_infinite]" />
                    </div>
                  )}

                  {inProgess
                    ? "Task in progress..."
                    : done
                    ? "Task finished"
                    : "Select a device"}
                </div>
              )}
              {done && (
                <div className="flex items-center">
                  <div className="w-3 h-3 mr-1">
                    <Check className="w-3 h-3 text-green-400" />
                  </div>
                  Task done
                </div>
              )}
            </div>
          </div>

          {(!done || check.state === CheckState.Bad) && (
            <div>
              <Button
                disabled={
                  !selectedDeviceId || (inProgess && !rejectedAndNotInProgress)
                }
                className="border px-2 py-1.5 rounded-md text-sm w-full mt-2 bg-white text-black"
                onClick={startDevice}
              >
                Platen uitprinten
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
