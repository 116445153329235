"use client";

import { useUser } from "@clerk/clerk-react";
import { ChevronsUpDown } from "lucide-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { languagesHooks } from "../../api";
import { SettingsContext } from "../context/SettingsManager";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/context-menu";

export default function LanguageSwitcher() {
  const { data: languages, isLoading } = languagesHooks.useGet(
    "/languages/search",
    {
      queries: {
        page: 1,
        size: 25,
      },
    },
  );

  const { languageCode, updateLanguageCode } = useContext(SettingsContext);
  const { t, i18n } = useTranslation();
  const { user } = useUser();

  async function handleChangeLanguage(code: string) {
    i18n.changeLanguage(code === "nl-NL" ? "nl-NL" : "en-EN");
    updateLanguageCode(code);

    //@ts-ignore
    const token = await window.Clerk.session.getToken({
      template: "jwt-template-1",
    });

    const result = await fetch(
      `${process.env.REACT_APP_MANAGEMENT_URL}/api/users/${user?.id}/language`,
      {
        method: "POST",
        body: JSON.stringify({ languageCode: code }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!result.ok) {
      alert("Something went wrong updating language, please try again...");
      return;
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button
          variant="ghost"
          className="pl-3 py-2 h-fit pr-2 dark:bg-surface-high bg-surface-low font-normal text-primary"
        >
          {isLoading && (
            <div className="animate-pulse bg-slate-200 w-20 h-6 rounded-lg"></div>
          )}
          {languageCode}
          <ChevronsUpDown className="ml-2 h-4 w-4 text-muted-foreground/60" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-52">
        {languages?.results?.map((language) => (
          <DropdownMenuItem
            key={language.id}
            onClick={() => {
              if (language.code) {
                handleChangeLanguage(language.code);
              }
            }}
          >
            {language.code}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
