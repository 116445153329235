import React, { PropsWithChildren } from "react";
import { TypographyTitleLargeElement } from "./styled";

export const TypographyTitleLarge: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TypographyTitleLargeElement className="text-xl">
      {children}
    </TypographyTitleLargeElement>
  );
};
