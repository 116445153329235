import React, { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ChecklistContext } from "../../context/ChecklistManager";
import CheckTemplate from "../../templates/CheckTemplate";

export const CheckPage: React.FC = () => {
  const { checkUid } = useParams<{ checkUid: string }>();
  const checklistContext = useContext(ChecklistContext);
  const checkViewModel = checklistContext.checklist.groups
    .find((s) => s.checks.find((c) => c.uid === checkUid))
    ?.checks.find((c) => c.uid === checkUid);

  return (
    <>
      {checkViewModel && <CheckTemplate checkViewModel={checkViewModel} />}
      <Outlet />
    </>
  );
};
