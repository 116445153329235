import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DefaultTheme, ThemeProvider } from "styled-components";
import * as serviceWorkerRegistration from "../../../serviceWorkerRegistration";
import { SettingsContext } from "../../context/SettingsManager";
import { Toaster } from "../../ui/toaster";
import ClerkProviderWithRoutes from "./clerk-routes";

const darkTheme: DefaultTheme = {
  primary: {
    main: "#D0BCFF",
    onMain: "#381E72",
  },
  primaryContainer: {
    main: "#4F378B",
    onMain: "#EADDFF",
  },
  secondaryContainer: {
    main: "#4A4458",
    onMain: "#E8DEF8",
  },
  background: {
    main: "#1C1B1E",
    onMain: "#E6E1E5",
  },
  surface: {
    main: "#313033",
    onMain: "#E6E1E5",
  },
  surfaceVariant: {
    main: "#49454F",
    onMain: "#CAC4D0",
  },
  error: {
    main: "#FFB4A9",
    onMain: "#680003",
  },
  errorContainer: {
    main: "#930006",
    onMain: "#FFDAD4",
  },
  outline: {
    main: "#948F99",
    onMain: "#948F99",
  },
  success: {
    main: "#73B77A",
    onMain: "#003d0c",
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1200,
    xl: 1536,
  },
} as const;

const lightTheme: DefaultTheme = {
  primary: {
    main: "#6750A4",
    onMain: "#FFFFFF",
  },
  primaryContainer: {
    main: "#4A4458",
    onMain: "#E8DEF8",
  },
  secondaryContainer: {
    main: "#E8DEF8",
    onMain: "#1D192B",
  },
  background: {
    main: "#FFFBFE",
    onMain: "#1C1B1F",
  },
  surface: {
    main: "#F4EFF4",
    onMain: "#1C1B1F",
  },
  surfaceVariant: {
    main: "#E7E0EC",
    onMain: "#49454F",
  },
  error: {
    main: "#BA1B1B",
    onMain: "#FFFFFF",
  },
  errorContainer: {
    main: "#FFDAD4",
    onMain: "#410001",
  },
  outline: {
    main: "#79757F",
    onMain: "#79757F",
  },
  success: {
    main: "#458C47",
    onMain: "#458C47",
  },
  breakpoints: {
    xs: 0,
    sm: 628,
    md: 1024,
    lg: 1200,
    xl: 1536,
  },
};

// This const get's replaced on build (Dockerfile). It's used to check
// the client version, and update it when it's outdated.
export const CHECKLAB_RELEASE_VERSION = "APP_RELEASE_VERSION";

export const App: React.FC = () => {
  const { darkMode } = useContext(SettingsContext);
  const location = useLocation();

  // maybe show a toast instead of reloading in the serviceWorkerRegistration.
  const [appUpdatePending, setAppUpdatePending] = useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(async (registration) => {
        await registration.update();
      });
    }
  }, [location]);

  const onSWUpdate = () => {
    setAppUpdatePending(true);
  };

  serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <ClerkProviderWithRoutes />
      <Toaster />
    </ThemeProvider>
  );
};
