import React, { PropsWithChildren } from "react";
import { TypographyBodyMediumElement } from "./styled";

export const TypographyBodyMedium: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TypographyBodyMediumElement className="text-sm font-semibold">
      {children}
    </TypographyBodyMediumElement>
  );
};
