import { ActivityEntity } from "../../core/domain/entities/checklist/ActivityEntity";
import { CommentType } from "../../infrastructure/api/common/types";
import { OriginReference } from "./CheckBaseViewModel";
import { PhotoViewModel } from "./PhotoViewModel";

export interface IActivityViewModel {
  uid: string;
  message: string;
  author: OriginReference | null;
  commentType: CommentType;
  createdAt: string;
  updatedAt: string;
  photos: PhotoViewModel[];
}

export class ActivityViewModel implements IActivityViewModel {
  public uid: string;
  public message: string;
  public author: OriginReference | null;
  public commentType: CommentType;
  public createdAt: string;
  public updatedAt: string;
  public photos: PhotoViewModel[];

  constructor(comment: ActivityEntity) {
    this.message = comment.message;
    this.author = comment.author;
    this.commentType = comment.commentType;
    this.createdAt = comment.createdAt;
    this.updatedAt = comment.updatedAt;
    this.uid = comment.uid;
    this.photos = comment.photos.map((p) => new PhotoViewModel(p));
  }
}
