import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";
import { ISignUndoChecklistRequest } from "../../../infrastructure/api/endpoints/common/request.types";

export class SignUndoChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(request: ISignUndoChecklistRequest): Promise<IResult> {
    return await this._checklistRepository.signUndoChecklist(request);
  }
}
