import styled from "styled-components/macro";

interface IListButtonElementProps {
  centerAligned: boolean;
  hasIcon: boolean;
  disabled: boolean;
  primary: boolean;
}

export const ListButtonElement = styled.div<IListButtonElementProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hasIcon ? "auto 1fr auto" : "1fr auto"};
  grid-column-gap: 12px;
  width: 100%;
  padding: ${(props) => (props.primary ? "12px 24px" : "12px 0")};
  border-radius: ${(props) => (props.primary ? "100px" : "0px")};
  background: ${(props) =>
    props.primary ? props.theme.primary.main : "transparent"};
  color: ${(props) =>
    props.primary ? props.theme.primary.onMain : props.theme.background.onMain};
  align-items: ${(props) => (props.centerAligned ? "center" : "flex-start")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const QuickActionElement = styled.div``;

export const IconWrapper = styled.div<{ primary: boolean }>`
  color: ${(props) =>
    props.primary ? props.theme.primary.onMain : props.theme.outline.onMain};
  opacity: 0.6;
`;

export const SubtitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  align-items: center;
`;
