import { Entity } from "../Entity";

export class WorkInstructionExampleEntity extends Entity {
  private _uri: string;

  protected constructor(uid: string, uri: string) {
    super(uid);

    this._uri = uri;
  }

  public get uri() {
    return this._uri;
  }

  public static new(uri: string) {
    return new WorkInstructionExampleEntity("non-existing", uri);
  }

  public static existing(uid: string, uri: string) {
    return new WorkInstructionExampleEntity(uid, uri);
  }
}
