import { makeObservable, observable } from "mobx";
import { CheckInputSingleLineEntity } from "../../core/domain/entities/checklist/check/CheckInputSingleLineEntity";
import { ISetCheckValueSingleTextRequest } from "../../infrastructure/api/endpoints/common/request.types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckInputSingleLineViewModel extends ICheckBaseViewModel {
  text: string | null;

  store: (data: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckInputSingleLineViewModel
  extends CheckBaseViewModel
  implements ICheckInputSingleLineViewModel
{
  public text: string | null = null;

  constructor(
    check: CheckInputSingleLineEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.text = check.text;

    this.finished = this.isFinished();

    makeObservable(this, {
      text: observable,
    });
  }

  public async store(data: string): Promise<void> {
    const tempState = this.text;

    await this.update<ISetCheckValueSingleTextRequest>(
      {
        text: data,
      },
      () => {
        this.text = data;
      },
      () => {
        this.text = tempState;
      },
    );
  }

  public async reset(): Promise<void> {
    const tempState = this.text;

    await this.update<ISetCheckValueSingleTextRequest>(
      {
        text: null,
      },
      () => {
        this.text = null;
      },
      () => {
        this.text = tempState;
      },
    );
  }
}
