import { makeObservable, observable } from "mobx";
import { z } from "zod";
import { schemas } from "../../api/schema";
import { CheckSignatureEntity } from "../../core/domain/entities/checklist/check/CheckSignatureEntity";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckSignatureViewModel extends ICheckBaseViewModel {
  signed: boolean;
  fileUri: string | null;

  sign: () => Promise<void>;
  reset: () => Promise<void>;
}

export type SignatureCheck = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto
> & { signed: boolean };

export type SignatureCheckUpdateRequest = Omit<
  z.infer<typeof schemas.Checklist_Check_Signature_SetValue_Body>,
  "checklistUid" | "groupUid" | "checkUid"
>;

export class CheckSignatureViewModel
  extends CheckBaseViewModel
  implements ICheckSignatureViewModel
{
  public signed: boolean = false;
  public fileUri: string | null = null;

  constructor(
    check: CheckSignatureEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.signed = check.signed;
    this.fileUri = check.fileUri;

    this.finished = this.isFinished();

    makeObservable(this, {
      signed: observable,
    });
  }

  public async sign(): Promise<void> {
    this.signed = true;
    this.finished = true;

    //@ts-ignore
    const x = { state: { selectedUser: { id: "" } } };

    const userId = //@ts-ignore
      window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID_NEW
        ? JSON.parse(localStorage.getItem("user-storage") || JSON.stringify(x))
            .state.loggedInUser?.userId
        : //@ts-ignore
          window.Clerk.user.id;

    this.lastTouchedBy = {
      value: userId,
      type: 1,
    };
    this.lastTouchedAt = new Date();
  }

  public async reset(): Promise<void> {
    this.signed = false;
    this.finished = false;
    this.lastTouchedBy = null;
  }
}
