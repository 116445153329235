import { mdiCheck, mdiClose, mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import TypographyBodyLarge from "../../atoms/TypographyBodyLarge";
import {
  IconWrapper,
  LabelElement,
  SelectIconWrapper,
  TextWrapper,
} from "./styled";

interface ILabelProps {
  text: string;
  icon?: string;
  select?: boolean;
  selected?: boolean;
  deletable?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
  type?: "assist" | "filter" | "input" | "suggestion";
}

export const Label: React.FC<ILabelProps> = ({
  text,
  icon,
  select,
  selected,
  deletable,
  onDelete,
  onClick,
  type = "suggestion",
}) => {
  return (
    <LabelElement
      className="border rounded-lg text-sm"
      deletable={deletable}
      select={select}
      icon={icon}
      selected={selected}
      onClick={onClick}
      variant={type}
    >
      {type === "filter" && selected && (
        <IconWrapper>
          <Icon path={mdiCheck} size={0.8} />
        </IconWrapper>
      )}
      {icon && (
        <IconWrapper>
          <Icon path={icon} size={0.8} />
        </IconWrapper>
      )}
      <TextWrapper>
        <span className="leading-none text-base">{text}</span>
      </TextWrapper>
      {select && (
        <SelectIconWrapper>
          <Icon path={mdiMenuDown} size={0.8} />
        </SelectIconWrapper>
      )}
      {deletable && (
        <SelectIconWrapper onClick={onDelete}>
          <Icon path={mdiClose} size={0.8} />
        </SelectIconWrapper>
      )}
    </LabelElement>
  );
};
