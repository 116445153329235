import { mdiCheckCircle, mdiCircleOutline, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useRef } from "react";
import {
  CheckStepperContainerElement,
  CheckStepperElement,
  ChecksStepperIconWrapperElement,
  ChecksStepperLineElement,
} from "./styled";

interface ICheckStepperProps {
  steps: { done: boolean; key: string; loading: boolean }[];
  activeIndex: number;
  onStepClick: (key: string) => void;
}

export const CheckStepper: React.FC<ICheckStepperProps> = ({
  steps,
  activeIndex,
  onStepClick,
}) => {
  const activeStepRef = useRef<HTMLDivElement>(null);
  const stepperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const left = activeStepRef.current?.offsetLeft ?? 0;
    const stepperWidth = stepperRef.current?.clientWidth ?? 0;

    stepperRef.current?.scrollTo({
      left: left - stepperWidth / 2 + 12,
      top: 0,
      behavior: "smooth",
    });
  }, [activeIndex]);

  return (
    <CheckStepperElement className="hidden" ref={stepperRef}>
      <CheckStepperContainerElement>
        <ChecksStepperLineElement />
        {steps.map((s, idx) => (
          <>
            <ChecksStepperIconWrapperElement
              className="cursor-pointer 1bg-background bg-card rounded-full"
              onClick={() => onStepClick(s.key)}
              done={s.done}
              active={idx === activeIndex}
              key={s.key}
              ref={idx === activeIndex ? activeStepRef : undefined}
            >
              {s.loading && (
                <Icon
                  className="animate-spin"
                  style={{ color: "white", position: "absolute", zIndex: 1 }}
                  path={mdiLoading}
                  size={0.9}
                />
              )}
              {!s.loading && (
                <Icon
                  path={mdiCircleOutline}
                  style={{ position: "absolute" }}
                  size={0.9}
                />
              )}
              {s.done && !s.loading && (
                <Icon
                  path={mdiCheckCircle}
                  className="text-emerald-600 dark:text-emerald-400   z-10"
                  size={0.9}
                />
              )}
            </ChecksStepperIconWrapperElement>
            {idx !== steps.length - 1 && <ChecksStepperLineElement />}
          </>
        ))}
        <ChecksStepperLineElement />
      </CheckStepperContainerElement>
    </CheckStepperElement>
  );
};
