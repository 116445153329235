import styled from "styled-components/macro";

export const ChecklistBodyContainerElement = styled.div`
  /* border-top: 2px solid ${(props) => props.theme.surfaceVariant.main}; */
`;

export const SignButtonContainerElement = styled.div`
  border: 1px solid ${(props) => props.theme.outline.main};
  //border-bottom: 1px solid ${(props) => props.theme.outline.main};
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 12px;
  //border-radius: 8px 8px 0 0;
`;

interface IProgressBarElementProps {
  progress: number;
}

export const ProgressBarElement = styled.div<IProgressBarElementProps>`
  height: 16px;
  border-radius: 16px;
  width: 100%;
  background: ${(props) => props.theme.surfaceVariant.main};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: ${(props) => props.progress}%;
    background: ${(props) => props.theme.surfaceVariant.onMain};
    height: 16px;
    transition: 0.2s;
    transition-timing-function: ease-out;
  }

  // Mask corners of :before, rounds corners based on border-radius.
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
`;
