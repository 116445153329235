import { makeObservable, observable } from "mobx";
import { CheckChecklistEntity } from "../../core/domain/entities/checklist/check/CheckChecklistEntity";
import { ChecklistBySerialQuery } from "../../core/queries/ChecklistBySerialQuery";
import { ISetCheckValueChecklistRequest } from "../../infrastructure/api/endpoints/common/request.types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckChecklistViewModel extends ICheckBaseViewModel {
  serialNumber: string | null;
  checklistExists: boolean;

  scan: (data: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckChecklistViewModel
  extends CheckBaseViewModel
  implements ICheckChecklistViewModel
{
  private _getChecklistBySerialNumberQuery = new ChecklistBySerialQuery();

  public serialNumber: string | null = null;
  public checklistExists: boolean = false;

  constructor(
    check: CheckChecklistEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.serialNumber = check.serialNumber;

    if (check.serialNumber) {
      this.setChecklistExists(check.serialNumber);
    }

    this.finished = this.isFinished();

    makeObservable(this, {
      serialNumber: observable,
    });
  }

  private async setChecklistExists(serialNumber: string): Promise<void> {
    const { isSuccessful } = await this._getChecklistBySerialNumberQuery.handle(
      serialNumber,
    );

    this.checklistExists = isSuccessful;
  }

  public async scan(data: string): Promise<void> {
    const tempState = this.serialNumber;

    await this.update<ISetCheckValueChecklistRequest>(
      {
        serialNumber: data,
      },
      () => {
        this.serialNumber = data;

        this.setChecklistExists(data);
      },
      () => {
        this.serialNumber = tempState;
      },
    );
  }

  public async reset(): Promise<void> {
    const tempState = this.serialNumber;

    await this.update<ISetCheckValueChecklistRequest>(
      {
        serialNumber: null,
      },
      () => {
        this.serialNumber = null;
        this.checklistExists = false;
      },
      () => {
        this.serialNumber = tempState;
      },
    );
  }
}
