import { PhotoEntity } from "../../core/domain/entities/checklist/PhotoEntity";

interface IPhotoViewModel {
  photoUri: string;
}

export class PhotoViewModel implements IPhotoViewModel {
  public photoUri: string;

  constructor(photo: PhotoEntity) {
    this.photoUri = photo.photoUri;
  }
}
