import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckInputSingleLineEntity extends ICheckBaseEntity {
  text: string | null;
}

export class CheckInputSingleLineEntity
  extends CheckBaseEntity
  implements ICheckInputSingleLineEntity
{
  public readonly text: string | null;

  protected constructor(check: ICheckInputSingleLineEntity) {
    super(check);

    this.text = check.text;
  }

  public static new(
    check: Omit<ICheckInputSingleLineEntity, "uid">,
  ): CheckInputSingleLineEntity {
    return new CheckInputSingleLineEntity({ uid: "non-existing", ...check });
  }

  public static existing(
    check: ICheckInputSingleLineEntity,
  ): CheckInputSingleLineEntity {
    return new CheckInputSingleLineEntity(check);
  }
}
