import { ChecklistRepository } from "../../infrastructure/repositories/ChecklistRepository";
import { ChecklistEntity } from "../domain/entities/checklist/ChecklistEntity";
import { IContentResult } from "../results/Result";

export class ChecklistBySerialQuery {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    serial: string,
    language?: string,
  ): Promise<IContentResult<ChecklistEntity>> {
    return await this._checklistRepository.getById(serial, language);
  }
}
