import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckEntityOkNotOk extends ICheckBaseEntity {
  rejectionMessages: { [x: string]: string };
  isUserRejectionMessageAllowed: boolean;
  selectedRejectionMessage: string | null;
}

export class CheckOkNotOkEntity
  extends CheckBaseEntity
  implements ICheckEntityOkNotOk
{
  public readonly rejectionMessages: { [x: string]: string };
  public readonly isUserRejectionMessageAllowed: boolean;
  public readonly selectedRejectionMessage: string | null;

  protected constructor(check: ICheckEntityOkNotOk) {
    super(check);

    this.rejectionMessages = check.rejectionMessages;
    this.isUserRejectionMessageAllowed = check.isUserRejectionMessageAllowed;
    this.selectedRejectionMessage = check.selectedRejectionMessage;
  }

  public static new(check: Omit<ICheckEntityOkNotOk, "uid">): CheckBaseEntity {
    return new CheckOkNotOkEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckEntityOkNotOk): CheckBaseEntity {
    return new CheckOkNotOkEntity(check);
  }
}
