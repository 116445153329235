import { mdiCameraPlusOutline, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import Compressor from "compressorjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TypographyTitleMedium from "../../atoms/TypographyTitleMedium";
import {
  PhotoInputButtonLabel,
  PhotoInputContainerElement,
  ThumbnailContainerElement,
  ThumbnailDeleteButtonElement,
  ThumbnailElement,
  ThumbnailImageElement,
} from "./styled";

interface IButtonProps {
  onChange: (photos: File[]) => void;
  onPhotoLoading: () => void;
  onPhotoFinished: () => void;
  disabled?: boolean;
  previews?: File[];
}

export const PhotoInput: React.FC<IButtonProps> = ({
  onChange,
  onPhotoLoading,
  onPhotoFinished,
  disabled = false,
  previews,
}) => {
  const [previewPhotos, setPreviewPhotos] = useState<File[]>([]);
  const maxAmountOfPhotos = 10;
  const { t } = useTranslation();

  const compressImage = async (file: File): Promise<File> => {
    return new Promise((r) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 800,
        maxHeight: 800,
        success(compressedFile: File) {
          r(compressedFile);
        },
      });
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onPhotoLoading();

    if (!e.target.files) {
      onPhotoFinished();
      return;
    }

    const fileList = e.target.files;
    const compressedPhotos: File[] = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      const compressedImageBlob = await compressImage(file);

      compressedPhotos.push(compressedImageBlob);
    }

    const newPreviewPhotos = [
      ...(previews ?? previewPhotos),
      ...compressedPhotos,
    ];

    if (newPreviewPhotos.length > maxAmountOfPhotos) {
      alert(
        `Je kan niet meer dan ${maxAmountOfPhotos} foto's tegelijk toevoegen...`,
      );

      return;
    }

    setPreviewPhotos(newPreviewPhotos);
    onChange(newPreviewPhotos);

    // Reset input value so if a file is removed and re-selected later it will actually get the new blob url.
    // If we don't clear it the same imaged won't be rendered.
    e.target.value = "";

    onPhotoFinished();
  };

  const handleRemove = (idx: number) => {
    const newPreviewPhotos = previewPhotos.filter((_, i) => i !== idx);

    setPreviewPhotos(newPreviewPhotos);
    onChange(newPreviewPhotos);
  };

  return (
    <>
      <PhotoInputContainerElement className={disabled ? "opacity-50" : ""}>
        <PhotoInputButtonLabel>
          <input
            disabled={disabled}
            type="file"
            multiple
            accept=".jpeg,.jpg"
            onChange={handleChange}
            hidden
          />
          <Icon size={0.9} path={mdiCameraPlusOutline} />
          <TypographyTitleMedium>
            {t("addActivityPage.uploadPhotos")} (
            {previews ? previews.length : previewPhotos.length}/
            {maxAmountOfPhotos})
          </TypographyTitleMedium>
        </PhotoInputButtonLabel>
      </PhotoInputContainerElement>
      {previews && previews.length > 0 && (
        <ThumbnailContainerElement>
          {previews.map((p, idx) => (
            <ThumbnailElement key={`${p.name}-${idx}`}>
              <ThumbnailDeleteButtonElement onClick={() => handleRemove(idx)}>
                <Icon size={1.2} path={mdiClose} />
              </ThumbnailDeleteButtonElement>
              <ThumbnailImageElement src={URL.createObjectURL(p)} />
            </ThumbnailElement>
          ))}
        </ThumbnailContainerElement>
      )}

      {!previews && previewPhotos.length > 0 && (
        <ThumbnailContainerElement>
          {previewPhotos.map((p, idx) => (
            <ThumbnailElement key={`${p.name}-${idx}`}>
              <ThumbnailDeleteButtonElement onClick={() => handleRemove(idx)}>
                <Icon size={1.2} path={mdiClose} />
              </ThumbnailDeleteButtonElement>
              <ThumbnailImageElement src={URL.createObjectURL(p)} />
            </ThumbnailElement>
          ))}
        </ThumbnailContainerElement>
      )}
    </>
  );
};
