import { UserButton } from "@clerk/clerk-react";
import TimeAgo from "javascript-time-ago";
import {
  ArrowRight,
  CheckCircle2,
  Circle,
  Folder,
  HelpCircle,
  Loader2,
  ScanLine,
  Search,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import nl from "relative-time-format/locale/nl";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import appRouter, { inpectionsHooks, projectsHooks } from "../../../api";
import { schemas } from "../../../api/schema";
import { useProjectStore } from "../../../stores/project-store";
import { SettingsContext } from "../../context/SettingsManager";
import { useScanner } from "../../hooks/useMemor10Scanner";
import ScannerPopup from "../../molecules/ScannerPopup";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import UserAvatar from "../../molecules/user-avatar";
import { Input } from "../../ui/input";
import { useToast } from "../../ui/use-toast";

TimeAgo.addLocale(nl);

// import { ApiOf, ZodiosResponseByPath } from "@zodios/core";
// export type Checklist = ZodiosResponseByPath<
//   ApiOf<typeof api.templateChecklists>,
//   "get",
//   "/templates/checklists/by-product-label/:productLabel"
// >;

export type TemplateChecklist = z.infer<
  typeof schemas.Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto
>;

export enum ChecklistState {
  Accepted = 1,
  ApprovalRequired = 2,
  InComplete = 3,
  //TODO: add enum value for checklist that have a rejected check (also for back-end).
  // Rejected = 4
}

const ChecklistStateLabelMap = {
  [ChecklistState.Accepted]: "Geaccepteerd",
  [ChecklistState.ApprovalRequired]: "Aftekenen",
  [ChecklistState.InComplete]: "Actief",
};

function useKeyboardWedgeInputScanner({
  onScanResult,
}: {
  onScanResult: (result: string) => void;
}) {
  const [scanResultState, setScanResultState] = React.useState("");
  const { isZebraScanner, barcodeRegex, barcodeSuffix, barcodePrefix } =
    useContext(SettingsContext);
  const startScanKey = barcodePrefix;
  const endScanKey = barcodeSuffix;
  const active = isZebraScanner;

  function getScanResult(result: string) {
    return `${result.replace(startScanKey, "").replace(endScanKey, "")}`.trim();
  }

  function handleScanResult(event: KeyboardEvent) {
    const x = event.key;

    if (
      scanResultState.includes(endScanKey.substring(0, endScanKey.length - 1))
    ) {
      const result = scanResultState + x;

      onScanResult(getScanResult(result));
      setScanResultState("");
    } else {
      setScanResultState((v) => v + x);
    }
  }

  React.useEffect(() => {
    if (active) {
      document.addEventListener("keypress", handleScanResult);
    }

    return () => {
      document.removeEventListener("keypress", handleScanResult);
    };
  }, [scanResultState]);

  const scanResult = getScanResult(scanResultState);

  return { scanResult, startScanKey, endScanKey };
}

enum InspectionsQueryOrdeBy {
  DefaultOrder = 0,
  CreatedAtAscending,
  CreatedAtDescending,
  UpdatedAtAscending,
  UpdatedAtDescending,
  AcceptedAtAscending,
  AcceptedAtDescending,
  TitleAscending,
  TitleDescending,
  SerialNumberAscending,
  SerialNumberDescending,
}

export const StartProjectPage: React.FC = () => {
  const timeAgo = new TimeAgo("nl-NL");
  const navigate = useNavigate();
  const { isZebraScanner, barcodeRegex } = useContext(SettingsContext);
  const [scanActive, setScanActive] = useState(false);
  const {
    scan,
    scanResult: scanResultUseScanner,
    scanMethod,
    reset,
    overrideScanMethod,
    setDefaultScanMethod,
    resetCamera,
    scanning,
  } = useScanner("projectSearchScanReader");
  const { toast } = useToast();

  const handleScan = async (data: string) => {
    resetCamera();
  };

  const navigateToProject = async (result: string) => {
    const regex = new RegExp(barcodeRegex);

    if (barcodeRegex !== "" && result.match(regex) === null) {
      setScanActive(false);

      toast({
        title: "Could not parse scan-result:" + JSON.stringify(result),
        variant: "destructive",
      });

      return;
    }

    const parsedResult = result.match(regex);
    const partialCode =
      barcodeRegex !== "" && parsedResult ? parsedResult[0] : result;

    const projects = await appRouter.projects.get("/projects/code/search", {
      queries: {
        partialCode: partialCode,
        page: 1,
        size: 25,
      },
    });

    const matchingProjects = projects.results?.filter(
      (project) => project.code === partialCode,
    );

    const [project] = matchingProjects ?? [];

    const projectId = project?.id;

    if (!projectId) {
      setScanActive(false);

      toast({
        title: "Project not found for scan-result:" + JSON.stringify(result),
        variant: "destructive",
      });

      return;
    }

    navigate(`/project/${projectId}`);
  };

  const [value, setValue] = React.useState("");

  const { scanResult, startScanKey, endScanKey } = useKeyboardWedgeInputScanner(
    {
      onScanResult: async (result) => {
        await navigateToProject(result);
      },
    },
  );

  const [serialNumberSearchValue, setSerialNumberSearchValue] = useState("");
  const [debouncedSerialNumberSearchValue] = useDebounce(
    serialNumberSearchValue,
    300,
  );

  const checklistsSearchEnabled = debouncedSerialNumberSearchValue !== "";

  const { data: checklists, isLoading: isLoadingChecklists } =
    projectsHooks.useGet(
      "/projects/code/search",
      {
        queries: {
          partialCode: debouncedSerialNumberSearchValue,
          page: 1,
          size: 25,
        },
      },
      { enabled: checklistsSearchEnabled },
    );

  useEffect(() => {
    document.getElementById("123")?.addEventListener("scroll", function () {
      if (
        document.activeElement &&
        document.activeElement.tagName === "INPUT"
      ) {
        (document.activeElement as HTMLInputElement).blur();
      }
    });
  }, []);

  const highlightedSpan = (text: string) => {
    if (!serialNumberSearchValue) return text;

    const regex = new RegExp(`(${serialNumberSearchValue})`, "gi");
    const rest = text.split(regex);

    return (
      <span>
        {rest.map((r) => {
          if (
            r.toLocaleLowerCase() ===
            serialNumberSearchValue.toLocaleLowerCase()
          ) {
            return (
              <span className="dark:bg-blue-700 bg-blue-300 rounded-sm" key={r}>
                {r}
              </span>
            );
          }

          return <span key={r}>{r}</span>;
        })}
      </span>
    );
  };

  const { data: checklists1, isLoading: allChecklistsIsLoading } =
    inpectionsHooks.useQuery(
      "/inspections/recent",
      {
        queries: {
          page: 1,
          size: 25,
        },
      },
      {
        refetchInterval: 10000,
      },
    );

  const { updateSelectedProject } = useProjectStore();

  const { data: projects, isLoading: isLoadingProjects } = projectsHooks.useGet(
    "/projects/recent",
    {
      queries: { page: 1, size: 25 },
    },
  );

  useEffect(() => {
    updateSelectedProject(null);
  }, []);

  const handleScannerScan = async () => {
    if (scanMethod === "text") {
      setScanActive(true);
      return;
    }

    if (scanMethod === "camera") {
      setScanActive(true);
    }

    try {
      await scan();
    } catch (e: unknown) {
      const { message } = e as Error;

      alert(`${message}`);
    }
  };

  useEffect(() => {
    if (scanMethod === "memor10" && scanResultUseScanner) {
      navigateToProject(scanResultUseScanner);
    }
  }, [scanResultUseScanner]);
  const { t } = useTranslation();

  return (
    <LayoutV2>
      {scanMethod === "camera" && (
        <ScannerPopup
          confirmLabel="Project openen"
          readerId={"projectSearchScanReader"}
          onManualInput={() => overrideScanMethod("text")}
          onDeleteResult={reset}
          open={scanActive}
          data={scanResultUseScanner}
          onConfirm={() => {
            navigateToProject(scanResultUseScanner ?? "");
          }}
          onClose={() => {
            resetCamera();
            setScanActive(false);
          }}
        />
      )}

      <div className="flex">
        <div className="1bg-surface-highest 1dark:bg-surface-lowest 1pr-4 sm:pl-0 pl-2 pr-2 pt-2 pb-2 grid grid-cols-[auto_1fr_1fr_1fr] sm:grid-cols-10 gap-y-2 w-full gap-x-2">
          <div className="col-span-6 h-fit flex items-center gap-x-2">
            <div className="relative w-full flex items-center mr-3 sm:mr-0">
              <Search className="absolute left-5 w-5 h-5 text-primary" />
              <form
                className="w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  (document.activeElement as HTMLInputElement).blur();
                }}
              >
                <Input
                  // If scanner input type is wedge use false else true.
                  autoFocus={false}
                  value={serialNumberSearchValue}
                  onChange={(e) => {
                    setSerialNumberSearchValue(e.target.value);
                  }}
                  className="rounded-xl text-base border-0 border-zinc-700 pl-14 h-12 pr-4 py-0 bg-surface-lowest dark:bg-surface-highest placeholder:text-muted-foreground"
                  placeholder={t("projectsPage.searchProjectLabel")}
                />
              </form>
              {/* TODO: hide only when wedge mode enabled... */}
              <div className="flex p-2 h-full absolute right-0">
                <button
                  className="border rounded-lg h-full px-2 bg-primary text-primary-foreground"
                  onClick={handleScannerScan}
                >
                  <ScanLine className="w-4 h-4 text-primary-foreground" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-4 flex items-center">
            <div className="ml-auto flex items-center">
              <a href={"mailto:info@checklab.nl"}>
                <HelpCircle className="w-6 h-6 mr-5 text-muted-foreground" />
              </a>
              <UserButton afterSignOutUrl={"/"} />
            </div>
          </div>

          {isZebraScanner && (
            <div className="relative px-3 col-span-10 rounded-md text-sm font-medium whitespace-nowrap bg-blue-400 text-black items-center flex h-6">
              <ScanLine className="w-4 h-4 mr-2" />
              {t("projectsPage.scanBarcodeToOpenProject")}
            </div>
          )}

          <div className="w-full sm:col-span-6 col-span-10">
            <div
              className={`bg-surface-low dark:bg-surface-high rounded-xl w-full overflow-auto ${
                isZebraScanner
                  ? "md:h-[calc(100vh-73px-32px)] h-[calc(100vh-134px-26px)]"
                  : "md:h-[calc(100vh-73px)] h-[calc(100vh-134px)]"
              }`}
              // style={{ height: "calc(100vh - 94px)" }}
              // style={{ height: "calc(100vh - 128px)" }}
              // style={{ height: "calc(100vh - 73px)" }}
            >
              <div className="1px-4 1border-t rounded-t-2xl 1bg-background 1border-dashed">
                <div className="px-6 pt-5 pb-2 flex items-center justify-between">
                  <span className="text-xl">{t("projectsPage.projects")}</span>
                </div>
                <div className="hidden 1border-b border-muted mb-0">
                  <span className="1border-b pt-4 pb-3 pl-6 text-sm font-medium text-muted-foreground block">
                    Zoekresultaten {checklists?.results?.length}
                  </span>
                </div>
                <div className="overflow-scroll" id="123">
                  {/* {debouncedSerialNumberSearchValue === "" && (
                    <div className="px-4">
                      <div className="mx-auto px-6 1w-full flex-col py-8 1bg-card rounded-xl w-fit flex items-center justify-center mt-2">
                        <div className="rounded-full mb-4 p-6 dark:bg-surface-highest bg-surface-lowest">
                          <FolderSearch className="w-8 h-8 text-muted-foreground" />
                        </div>
                        <span className="text-center text-sm text-muted-foreground/60 font-medium">
                          Start met zoeken door een projectnummer in <br /> te
                          vullen of een label te scannen.{" "}
                        </span>
                        <div className="hidden flex gap-x-4 mt-4">
                          <Button className="h-12 mt-4 bg-[#cac4d0] rounded-full">
                            Project aanmaken
                          </Button>
                          <Button
                            variant={"outline"}
                            className="mt-4 h-12 border-[#cac4d0] text-[#cac4d0] rounded-full"
                          >
                            Checklist zoeken
                          </Button>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {(checklistsSearchEnabled
                    ? isLoadingChecklists
                    : isLoadingProjects) && (
                    <div className="px-4">
                      <div className="w-full h-36 flex items-center justify-center mt-4">
                        <Loader2 className="w-4 h-4 text-muted-foreground animate-spin" />
                      </div>
                    </div>
                  )}

                  {debouncedSerialNumberSearchValue !== "" &&
                    !isLoadingChecklists &&
                    checklists?.results &&
                    checklists?.results.length < 1 && (
                      <div className="px-4">
                        <div className="w-full h-36 flex items-center justify-center mt-4">
                          <span className="text-sm text-muted-foreground/60 font-medium">
                            Geen projecten gevonden...
                          </span>
                        </div>
                      </div>
                    )}

                  {(checklistsSearchEnabled
                    ? checklists
                    : projects
                  )?.results?.map((project) => (
                    <div
                      key={project.id}
                      className="px-2 hover:bg-black/5 last:border-none 1border-b border-muted cursor-pointer duration-150 ease-out"
                    >
                      <div
                        onClick={() => {
                          navigate(`/project/${project.id}`);
                          updateSelectedProject(project);
                        }}
                        className={`pl-2 pr-3 py-2 flex items-center h-fit`}
                      >
                        <div className="bg-surface-lowest dark:bg-surface-highest w-12 h-12 rounded-lg mr-2 flex items-center justify-center">
                          <Folder className="w-6 h-6 text-muted-foreground" />
                        </div>
                        <div>
                          <span className="leading-none block text-sm font-medium text-card-foreground">
                            {highlightedSpan(project.code ?? "")}
                          </span>
                          <span className="leading-none mt-1.5 block text-sm font-medium text-muted-foreground/60">
                            {project?.createdAt &&
                              timeAgo.format(
                                new Date(project?.createdAt ?? 0),
                                "mini-minute-now",
                              )}
                          </span>
                        </div>

                        <div className="flex items-center gap-x-3 ml-auto">
                          <div className="bg-background/60 rounded-full p-1.5 flex justify-center items-center ml-auto">
                            <ArrowRight className="w-4 h-4 text-muted-foreground" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-4 hidden sm:block">
            <div
              className={`bg-surface-container rounded-xl w-full overflow-auto hide-scrollbar ${
                isZebraScanner
                  ? "md:h-[calc(100vh-73px-32px)] h-[calc(100vh-134px-26px)]"
                  : "md:h-[calc(100vh-73px)] h-[calc(100vh-134px)]"
              }`}
              // style={{ height: "calc(100vh - 94px)" }}
              // style={{ height: "calc(100vh - 128px)" }}
              // style={{ height: "calc(100vh - 73px - 26px)" }}
            >
              <div className="px-6 pt-5 pb-2 flex items-center justify-between">
                <span className="text-xl">
                  {t("projectsPage.recentInspections")}
                </span>
                {/* <Search className="w-5 h-5 text-muted-foreground" /> */}
              </div>
              <div className="">
                {checklists1?.results?.map((checklist) => (
                  <div
                    onClick={() => {
                      navigate(`/checklist/${checklist.id}/?check-popup=open`);
                    }}
                    key={checklist.id}
                    className={`px-4 cursor-pointer py-2 grid grid-cols-[auto_1fr_auto] items-center h-full duration-150 ease-out hover:dark:bg-black/5`}
                  >
                    <div className="bg-surface-high dark:bg-surface-high mr-2.5 rounded-lg justify-center flex items-center text-sm text-muted-foreground font-medium h-12 w-12">
                      {checklist.state === 1 && (
                        <CheckCircle2 className="h-4 w-4 dark:text-emerald-400 text-green-500" />
                      )}
                      {checklist.state === 2 && (
                        <Circle className="h-4 w-4 text-muted-foreground" />
                      )}
                      {checklist.state === 3 && (
                        <span className="relative flex items-center justify-center text-yellow-500">
                          <div className="border-[3px] border-surface-high w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                          <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                        </span>
                      )}
                    </div>
                    <div className="w-full overflow-hidden truncate">
                      <span className="leading-none block text-sm font-semibold 1text-card-foreground text-primary">
                        {checklist.serialNumber}{" "}
                      </span>
                      <span className="leading-none overflow-hidden truncate font-medium mt-1.5 flex items-center text-sm text-muted-foreground/60">
                        <span
                          className={`bg-surface-high ${
                            (checklist.percentage ?? 0) >= 100
                              ? "dark:text-emerald-400 text-green-500"
                              : ""
                          } dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5`}
                        >
                          {checklist.percentage?.toFixed()}%
                        </span>
                        <div className="mx-1.5 h-1 w-1 rounded-full bg-white/20"></div>
                        <span className="truncate overflow-hidden w-fit">
                          {checklist.title}
                        </span>
                        {/* <div className="mx-1.5 h-1 w-1 rounded-full bg-white/20"></div>
                        <span className="truncate overflow-hidden max-w-[70%]">
                          {checklist?.createdAt &&
                            timeAgo.format(
                              new Date(checklist?.createdAt ?? 0),
                              "mini-minute-now",
                            )}
                        </span> */}
                      </span>
                    </div>
                    <div className="ml-auto font-medium flex flex-col items-end gap-x-2">
                      <UserAvatar
                        small
                        narrow
                        left
                        id={checklist.createdBy?.value}
                      />
                      <span className="mt-1.5 whitespace-nowrap text-muted-foreground/60 bg-surface-high dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5">
                        {checklist?.createdAt &&
                          timeAgo.format(
                            new Date(checklist?.createdAt ?? 0),
                            "mini-minute-now",
                          )}
                      </span>
                    </div>
                    {/* <div className="hidden ml-auto pt-0.5">
                      <ArrowRight className="w-4 h-4 text-muted-foreground" />
                    </div> */}
                  </div>
                ))}

                {/* <div className="rounded-2xl bg-surface-low px-4 py-3">
                  <span className="text-sm block font-semibold">
                    00000000123
                  </span>
                  <span className="text-sm block text-muted-foreground">
                    Checklist
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
};
