import { ArrowLeft, ClipboardCheck, PackageCheck } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";

export const AddChecklistToProjectPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const projectIdNumber = Number(projectId);

  return (
    <LayoutV2>
      <div className="px-4 border-b mb-4 flex items-center">
        <div
          onClick={() => {
            navigate(`/project/${projectIdNumber}`);
          }}
        >
          <ArrowLeft className="w-4 h-4 mr-2 text-muted-foreground" />
        </div>
        <span className="text-lg font-semibold py-4 block">
          Checklist toevoegen aan project
        </span>
      </div>
      <div className="px-4 flex gap-x-4">
        <button
          onClick={() => navigate(`/product-checklist/${projectIdNumber}`)}
          className="bg-card w-full h-36 rounded-lg 1border font-medium text-sm flex-col text-muted-foreground  flex items-center justify-center"
        >
          <PackageCheck className="text-primary w-7 h-7 mb-4" />
          Product checklist
        </button>
        <button
          onClick={() => navigate(`/project-checklist/${projectIdNumber}`)}
          className="bg-card w-full h-36 rounded-lg font-medium 1border text-sm text-muted-foreground  flex flex-col items-center justify-center"
        >
          <ClipboardCheck className="text-primary w-7 h-7 mb-4" />
          Project checklist
        </button>
      </div>
    </LayoutV2>
  );
};
