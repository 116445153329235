import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";

export class LeaveChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(serial: string): Promise<IResult> {
    return await this._checklistRepository.leaveChecklist(serial);
  }
}
