import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckSignatureEntity extends ICheckBaseEntity {
  signed: boolean;
  fileUri: string | null;
}

export class CheckSignatureEntity
  extends CheckBaseEntity
  implements ICheckSignatureEntity
{
  public readonly signed: boolean;
  public readonly fileUri: string | null;

  protected constructor(check: ICheckSignatureEntity) {
    super(check);

    this.signed = check.signed;
    this.fileUri = check.fileUri;
  }

  public static new(
    check: Omit<ICheckSignatureEntity, "uid">,
  ): CheckSignatureEntity {
    return new CheckSignatureEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckSignatureEntity): CheckSignatureEntity {
    return new CheckSignatureEntity(check);
  }
}
