import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { ChecklistViewModel } from "../../../application/viewModels/ChecklistViewModel";
import { ChecklistManager } from "../../context/ChecklistManager";
import { SettingsContext } from "../../context/SettingsManager";
import ChecklistTemplate from "../../templates/ChecklistTemplate";
import { CheckPageContainer, ChecklistPageContainer } from "./styled";

export const ChecklistPage: React.FC = () => {
  const { serialNumber } = useParams<{
    serialNumber: string | undefined;
  }>();
  const [searchParams] = useSearchParams();

  const [checklistViewModel, setChecklistViewModel] =
    useState<ChecklistViewModel>();

  const { languageCode } = useContext(SettingsContext);

  useEffect(() => {
    setChecklistViewModel(
      () => new ChecklistViewModel(serialNumber ?? "", languageCode),
    );
  }, [serialNumber]);

  const projectId = searchParams.get("projectId");

  return (
    <div className="1container">
      {/*Todo: DevOps#776.*/}
      {checklistViewModel && (
        <ChecklistManager checklist={checklistViewModel}>
          <span></span>
          <ChecklistPageContainer>
            <div className="grid grid-cols-1 md:grid-cols-10 1pb-6">
              <div className="col-span-10 md:col-span-5 lg:col-span-6">
                <ChecklistTemplate
                  projectId={
                    projectId !== undefined ? Number(projectId) : undefined
                  }
                />
              </div>
              <div className="col-span-10 md:col-span-5 lg:col-span-4 bg-surface-highest dark:bg-surface-lowest">
                <CheckPageContainer
                  className="w-full pr-2 sm:pt-2 lg:right-0 lg:z-10"
                  // style={{ width: "calc(50% - 40px)" }}
                >
                  <Outlet />
                </CheckPageContainer>
              </div>
            </div>
          </ChecklistPageContainer>
        </ChecklistManager>
      )}
      {/* {projectData !== undefined && ( */}
      {/* <div
          className="fixed bottom-[58px] md:bottom-[78px] left-0 px-2 z-30 w-full flex justify-center"
          onClick={() => navigate(`/project/${projectId}`)}
        > */}
      {/* <div className="cursor-pointer px-2 py-2 max-w-lg rounded-lg flex 1bg-slate-700 1bg-blue-600 bg-[#cac4d0] border bg-background 1bg-card/50 backdrop-blur-lg 1border w-full items-center shadow-lg">
            <div className="bg-white/10 1border w-12 h-12 rounded-md mr-2 flex items-center justify-center"> */}
      {/* <Folder className="w-6 h-6 text-white/70" /> */}
      {/* <Folder className="w-6 h-6 text-emerald-500" />
            </div>
            <div>
              <span className="text-sm font-semibold block">
                {projectData.code}
              </span>
              <div className="flex items-center mt-1">
                <span className="text-xs block text-white/60">Project</span>
                <div className="mx-1.5 h-1 w-1 rounded-full bg-white/30"></div>
                <span className="text-xs block text-white/60">
                  {projectData.checklists?.length} inspecties
                </span>
              </div>
            </div>
            <div className="ml-auto">
              <div className="flex items-center gap-x-4">
                {/* <Info className="w-6 h-6 text-white/60" /> */}
      {/* <Info className="w-6 h-6 text-white/60" /> */}
      {/* <ChevronRight className="w-6 h-6 text-white/60" />
              </div>
            </div>
          </div>
        </div>  */}
      {/* )} */}
    </div>
  );
};
