import { useQuery } from "@tanstack/react-query";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../ui/hover-card";
import { Skeleton } from "../ui/skeleton";

export default function UserAvatar({
  id,
  left = false,
  narrow = true,
  name = true,
  small = false,
}: {
  id?: string | null;
  left?: boolean;
  narrow?: boolean;
  name?: boolean;
  small?: boolean;
}) {
  const { data: user, isLoading } = useQuery({
    queryKey: [`/api/users/${id}`],
    queryFn: getClerkUser,
    enabled: !!id,
  });

  async function getClerkUser() {
    //@ts-ignore
    const token = await window.Clerk.session.getToken({
      template: "jwt-template-1",
    });

    const user = await fetch(
      `${process.env.REACT_APP_MANAGEMENT_URL}/api/users/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    const data = await user.json();

    return data.user as any;
  }

  function arrayHasValue(arr?: unknown[] | null) {
    return !!arr?.length;
  }

  if (isLoading) {
    return (
      <div className="flex items-center gap-x-2">
        {left && name && <Skeleton className="w-16 h-4" />}
        <Skeleton className={`${small ? "w-5 h-5" : "w-6 h-6"} rounded-full`} />
        {!left && name && <Skeleton className="w-16 h-4" />}
      </div>
    );
  }

  return (
    <HoverCard openDelay={300}>
      <HoverCardTrigger asChild>
        <div className={`flex items-center ${narrow ? "gap-x-2" : "gap-x-4"}`}>
          {left && name && (
            <span className="text-sm">
              {user?.firstName ?? "Gebruiker laden..."}
            </span>
          )}
          <Avatar
            className={`${
              small ? "w-5 h-5" : "w-6 h-6"
            } border border-border/20`}
          >
            <AvatarFallback>?</AvatarFallback>
            <AvatarImage src={user?.imageUrl} />
          </Avatar>
          {!left && name && (
            <span className="text-sm">
              {user?.firstName ?? "Gebruiker laden..."}
            </span>
          )}
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="w-fit">
        <div className="flex justify-between space-x-4">
          <Avatar>
            <AvatarImage src={user?.imageUrl} />
            <AvatarFallback>VC</AvatarFallback>
          </Avatar>
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">
              {user?.firstName} {user?.lastName}
            </h4>
            <p className="text-sm">
              {user?.emailAddresses?.map((e: any) => e.emailAddress).join(", ")}
            </p>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
