import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckScanEntity extends ICheckBaseEntity {
  barcode: string | null;
}

export class CheckScanEntity
  extends CheckBaseEntity
  implements ICheckScanEntity
{
  public readonly barcode: string | null;

  protected constructor(check: ICheckScanEntity) {
    super(check);

    this.barcode = check.barcode;
  }

  public static new(check: Omit<ICheckScanEntity, "uid">): CheckScanEntity {
    return new CheckScanEntity({ uid: "non-existing", ...check });
  }

  public static existing(check: ICheckScanEntity): CheckScanEntity {
    return new CheckScanEntity(check);
  }
}
