import { Entity } from "../Entity";
import { WorkInstructionExampleEntity } from "./WorkInstructionExampleEntity";

export class WorkInstructionEntity extends Entity {
  private _title: string;
  private _description: string;
  private _correctExamples: WorkInstructionExampleEntity[];
  private _wrongExamples: WorkInstructionExampleEntity[];

  constructor(
    uid: string,
    title: string,
    description: string,
    correctExamples: WorkInstructionExampleEntity[],
    wrongExamples: WorkInstructionExampleEntity[],
  ) {
    super(uid);

    this._title = title;
    this._description = description;
    this._correctExamples = correctExamples;
    this._wrongExamples = wrongExamples;
  }

  public get title() {
    return this._title;
  }

  public get description() {
    return this._description;
  }

  public get correctExamples() {
    return this._correctExamples;
  }

  public get wrongExamples() {
    return this._wrongExamples;
  }

  public static new(
    title: string,
    description: string,
    correctExamples: WorkInstructionExampleEntity[],
    wrongExamples: WorkInstructionExampleEntity[],
  ) {
    return new WorkInstructionEntity(
      "non-existing",
      title,
      description,
      correctExamples,
      wrongExamples,
    );
  }

  public static existing(
    uid: string,
    title: string,
    description: string,
    correctExamples: WorkInstructionExampleEntity[],
    wrongExamples: WorkInstructionExampleEntity[],
  ) {
    return new WorkInstructionEntity(
      uid,
      title,
      description,
      correctExamples,
      wrongExamples,
    );
  }
}
