import styled, { css } from "styled-components/macro";

interface ILabelElementProps {
  select?: boolean;
  selected?: boolean;
  deletable?: boolean;
  icon?: string;
  variant?: "assist" | "filter" | "input" | "suggestion";
}

const selectedLabel = css`
  background: ${(props) => props.theme.secondaryContainer.main};
  outline: none;
`;

const selectedFilterVariant = css<ILabelElementProps>`
  grid-column-gap: 8px;
  padding: 6px ${(props) => (props.select ? "8px" : "16px")} 6px 8px;
`;

export const LabelElement = styled.div<ILabelElementProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.select || props.deletable ? "auto 1fr auto" : "auto 1fr"};
  grid-column-gap: ${(props) =>
    props.icon || props.select || props.deletable ? "8px" : "0"};
  align-items: center;
  padding: ${(props) =>
    props.icon
      ? `6px ${props.select || props.deletable ? "8px" : "16px"} 6px 8px`
      : `6px ${props.select || props.deletable ? "0px" : "16px"} 6px 16px`};
  background: transparent;
  color: ${(props) => props.theme.surfaceVariant.onMain};
  /* outline: 1px solid ${(props) => props.theme.outline.main}; */
  /* border-radius: 8px; */
  width: fit-content;
  min-height: 32px;
  height: auto;
  ${(props) => (props.selected ? selectedLabel : "")}
  ${(props) =>
    props.selected && props.variant === "filter" ? selectedFilterVariant : ""}
`;

export const IconWrapper = styled.div`
  color: ${(props) => props.theme.primary.main};
  display: flex;
`;

export const SelectIconWrapper = styled.div`
  color: ${(props) => props.theme.surfaceVariant.onMain};
  display: flex;
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
