import { makeObservable, observable } from "mobx";
import { CheckSingleOptionEntity } from "../../core/domain/entities/checklist/check/CheckSingleOptionEntity";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckSingleOptionViewModel extends ICheckBaseViewModel {
  selectedChoice: string | null;
  choices: { [s: string]: string } | null;

  answer: (optionKey: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckSingleOptionViewModel
  extends CheckBaseViewModel
  implements ICheckSingleOptionViewModel
{
  public selectedChoice: string | null = null;
  public choices: { [s: string]: string } | null = null;

  constructor(
    check: CheckSingleOptionEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.choices = check.choices;
    this.selectedChoice =
      this.choices && check.selectedChoice
        ? this.choices[check.selectedChoice ?? ""]
        : null;

    this.finished = this.isFinished();

    makeObservable(this, {
      selectedChoice: observable,
    });
  }

  public async answer(option: string): Promise<void> {
    this.selectedChoice = this.choices ? this.choices[option] : "";
    this.finished = true;

    const x = { state: { selectedUser: { id: "" } } };

    const userId = //@ts-ignore
      window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID_NEW
        ? JSON.parse(localStorage.getItem("user-storage") || JSON.stringify(x))
            .state.loggedInUser?.userId
        : //@ts-ignore
          window.Clerk.user.id;

    this.lastTouchedBy = {
      value: userId,
      type: 1,
    };
    this.lastTouchedAt = new Date();
  }

  public async reset(): Promise<void> {
    this.selectedChoice = null;
    this.finished = false;
    this.lastTouchedBy = null;
  }
}
