import { ISetCheckValueBaseRequest } from "../../../infrastructure/api/endpoints/common/request.types";
import { ISetCheckValueResponse } from "../../../infrastructure/api/endpoints/common/response.types";
import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IContentResult } from "../../results/Result";

export class CheckSetValueCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle<T>(
    request: ISetCheckValueBaseRequest<T>,
  ): Promise<IContentResult<ISetCheckValueResponse>> {
    const { checklistUid, checkUid } = request;

    const checklistResult = await this._checklistRepository.getById(
      checklistUid,
    );

    if (checklistResult.isNotSuccessful)
      // Todo: do this in a clean way
      return checklistResult as unknown as IContentResult<ISetCheckValueResponse>;

    const check = checklistResult.content?.groups
      .find((s) => s.checks.find((c) => c.uid === checkUid))
      ?.checks.find((c) => c.uid === checkUid);

    if (!check) throw Error("Check does not exist in this checklist.");

    return await this._checklistRepository.checkSetValue(request);
  }
}
