import React, {PropsWithChildren, useRef} from "react";
import {
  DrawerBodyElement,
  DrawerContainerElement,
  DrawerElement,
  DrawerHeaderElement,
} from "./styled";
import Grid from "../../atoms/Grid";
import { AnimatePresence } from "framer-motion";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";

const animationVariantsContainer = {
  initial: {
    y: 125,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", ease: "circOut", duration: 0.2 },
  },
  exit: {
    y: 125,
    opacity: 0,
    transition: { type: "tween", ease: "easeIn", duration: 0.2 },
  },
};

const animationVariantsBackDrop = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { type: "tween", ease: "easeOut", duration: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { type: "tween", ease: "easeOut", duration: 0.2 },
  },
};

interface IDrawerProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

export const Drawer: React.FC<PropsWithChildren<IDrawerProps>> = ({
  open,
  onClose,
  title,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const node = event.target;

    if (containerRef.current === node) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {open && (
        <DrawerElement
          variants={animationVariantsBackDrop}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <DrawerContainerElement
            variants={animationVariantsContainer}
            initial="initial"
            animate="animate"
            exit="exit"
            ref={containerRef}
            onClick={(e) => handleClick(e)}
          >
            <DrawerBodyElement>
              <DrawerHeaderElement>
                <TypographyBodyMedium>{title}</TypographyBodyMedium>
              </DrawerHeaderElement>
              <Grid padded spacing={2}>
                {children}
              </Grid>
            </DrawerBodyElement>
          </DrawerContainerElement>
        </DrawerElement>
      )}
    </AnimatePresence>
  );
};
