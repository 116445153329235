import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { Portal } from "react-portal";
import Grid from "../../atoms/Grid";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import TypographyHeadlineSmall from "../../atoms/TypographyHeadlineSmall";
import Button from "../Button";
import { ButtonVariant } from "../Button/Button";
import {
  BackdropElement,
  DialogButtonContainerElement,
  DialogElement,
  DialogTitleWrapperElement,
  DialogWrapperElement,
  InputElement,
} from "./styled";

interface IChipProps<T extends string | number> {
  title: string;
  text?: string;
  open?: boolean;
  onAction?: () => void;
  onSubmit?: (value: T) => void;
  onCancel?: () => void;
  onClose?: () => void;
  variant?: "regular";
  type?: "prompt";
  inputType?: "number" | "text";
}

const animationVariantsDialog = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  exit: {},
};

export const Dialog = <T extends string | number>({
  title,
  text,
  open,
  onSubmit,
  onCancel,
  onClose,
  variant = "regular",
  type = "prompt",
  inputType = "text",
}: IChipProps<T>): JSX.Element => {
  const [value, setValue] = useState<T | undefined>();

  const handleClose = () => {
    setValue(undefined);
    onClose?.();
  };

  const handleSubmit = () => {
    if (!value) return;

    onSubmit?.(value);
    handleClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value as T);
  };

  return (
    <Portal>
      <AnimatePresence>
        {open && (
          <DialogWrapperElement>
            <BackdropElement onClick={onClose} />
            <DialogElement
              variants={animationVariantsDialog}
              initial="initial"
              animate="animate"
              exit="exit"
              variant={variant}
            >
              <Grid padded>
                <DialogTitleWrapperElement>
                  <TypographyHeadlineSmall>{title}</TypographyHeadlineSmall>
                </DialogTitleWrapperElement>
                <TypographyBodyMedium>{text}</TypographyBodyMedium>
                <InputElement
                  onChange={(e) => {
                    if (
                      inputType === "number" &&
                      Math.abs(Number(e.target.value)) >=
                        Number.MAX_SAFE_INTEGER
                    ) {
                      return;
                    }

                    handleInputChange(e);
                  }}
                  placeholder="Invoeren"
                  type={inputType}
                  value={value}
                  maxLength={250}
                  autoFocus
                />
                <DialogButtonContainerElement>
                  <Button onClick={handleClose} variant={ButtonVariant.text}>
                    Annuleren
                  </Button>
                  <Button onClick={handleSubmit} variant={ButtonVariant.text}>
                    Opslaan
                  </Button>
                </DialogButtonContainerElement>
              </Grid>
            </DialogElement>
          </DialogWrapperElement>
        )}
      </AnimatePresence>
    </Portal>
  );
};
