import { Loader, Loader2 } from "lucide-react";
import React, { useContext } from "react";
import appRouter from "../../../api";
import { SettingsContext } from "../../context/SettingsManager";

const useScanner = ({
  onScanResult,
}: {
  onScanResult: (
    result: string,
    unparsedResult: string,
    regexedResult: string,
  ) => void;
}) => {
  const [scanResultState, setScanResultState] = React.useState("");
  const { isZebraScanner, barcodeRegex, barcodeSuffix, barcodePrefix } =
    useContext(SettingsContext);
  const startScanKey = barcodePrefix;
  const endScanKey = barcodeSuffix;
  const active = isZebraScanner;

  function regexedResult(result: string) {
    const regex = new RegExp(barcodeRegex);

    if (barcodeRegex !== "" && result.match(regex) === null) {
      return;
    }

    const parsedResult = result.match(regex);
    const partialCode =
      barcodeRegex !== "" && parsedResult ? parsedResult[0] : result;

    return partialCode;
  }

  function getScanResult(result: string) {
    return `${result.replace(startScanKey, "").replace(endScanKey, "")}`.trim();
  }

  function handleScanResult(event: KeyboardEvent) {
    const x = event.key;

    if (
      scanResultState.includes(endScanKey.substring(0, endScanKey.length - 1))
    ) {
      const result = scanResultState + x;

      onScanResult(
        getScanResult(result),
        result,
        regexedResult(getScanResult(result)) ?? "Failed to regex result...",
      );
      setScanResultState("");
    } else {
      setScanResultState((v) => v + x);
    }
  }

  React.useEffect(() => {
    if (active) {
      document.addEventListener("keypress", handleScanResult);
    }

    return () => {
      document.removeEventListener("keypress", handleScanResult);
    };
  }, [scanResultState]);

  return { startScanKey, endScanKey };
};

export const ZebraScannerTestPage: React.FC = () => {
  const [value, setValue] = React.useState("");
  const [unparsedValue, setUnparsedValue] = React.useState("");
  const [regexedResult, setRegexedResult] = React.useState("");
  const [projectId, setProjectId] = React.useState<number>();

  const { isZebraScanner, barcodeRegex, barcodeSuffix, barcodePrefix } =
    useContext(SettingsContext);

  const { startScanKey, endScanKey } = useScanner({
    onScanResult: async (result, unparsedResult, regexedResult) => {
      setValue(result);
      setUnparsedValue(unparsedResult);
      setRegexedResult(regexedResult);
      setProjectId(undefined);

      const projects = await appRouter.projects.get("/projects/code/search", {
        queries: {
          partialCode: regexedResult,
          page: 1,
          size: 25,
        },
      });

      const matchingProjects = projects.results?.filter(
        (project) => project.code === regexedResult,
      );

      const [project] = matchingProjects ?? [];

      const projectId = project?.id;

      if (projectId) {
        setProjectId(projectId);
      }
    },
  });

  return (
    <div className="p-3">
      <div className="rounded-lg font-mono text-xs dark bg-black p-3">
        <a href="/settings" className="block text-blue-500 mb-3 mt-1">
          {"<- Go back to settings"}
        </a>
        <span className="text-muted-foreground">
          -------- DataWedge Scanner Result --------
        </span>
        <div className="text-muted-foreground">
          {"Raw result >>"}
          <span className="text-red-500">
            {unparsedValue === ""
              ? "Waiting for scan result..."
              : unparsedValue}
          </span>
          {"<<"}
        </div>

        <div className="text-muted-foreground">
          {"Unparsed result >>"}
          <span className="text-yellow-500">
            {value === "" ? "Waiting for scan result..." : value}
          </span>
          {"<<"}
        </div>

        <div className="text-muted-foreground">
          {"Parsed result >>"}
          <span className="text-green-500">
            {regexedResult === ""
              ? "Waiting for scan result..."
              : regexedResult}
          </span>
          {"<<"}
        </div>

        <div className="border text-xs border-dashed py-1 px-2 mt-1 rounded-md">
          {projectId && (
            <span className="block text-green-500">
              Project found for project code: {regexedResult}
            </span>
          )}

          {regexedResult && !projectId && (
            <span className="text-red-500 block">
              No project found for project code: {regexedResult}
            </span>
          )}

          {!regexedResult && !projectId && (
            <div className="flex items-center text-muted-foreground/60">
              <span className="block">
                Waiting for scan result to load project...
              </span>
            </div>
          )}
        </div>

        <div className="mt-2">
          <span className="text-muted-foreground">
            -------- Checklab Scanner Settings --------
          </span>
          <div className="text-yellow-500 text-xs border-yellow-500 border border-dashed py-1 px-2 rounded-md mt-1 mb-1">
            <span>Important: </span>
            Ensure that the raw result (highlighted in{" "}
            <span className="text-red-500">red</span> above) includes the
            {"'CLBarcodePrefix'"} and {"'CLBarcodeSuffix'"} as specified below
            (these settings can be changed{" "}
            <a href="/settings" className="text-blue-500 mb-3 mt-1">
              {"here"}
            </a>
            ). Verify this by scanning a barcode. If not, check your scanner
            settings to ensure it
            {"'"}s set to DataWedge. Confirm the scanner prefix and suffix
            settings as well. For assistance, contact{" "}
            <a href="mailto:info@discbv.nl" className="text-blue-500 mb-3 mt-1">
              {"info@discbv.nl"}
            </a>
            .
          </div>
          <div>
            <span className="text-muted-foreground">
              CLBarcodePrefix {">>"}
            </span>
            {`${barcodePrefix}`}
            <span className="text-muted-foreground">{"<<"}</span>
          </div>
          <div>
            <span className="text-muted-foreground">
              CLBarcodeSuffix {">>"}
            </span>
            {`${barcodeSuffix}`}
            <span className="text-muted-foreground">{"<<"}</span>
          </div>
          <div>
            <span className="text-muted-foreground">CLBarcodeRegex {">>"}</span>
            {`${barcodeRegex}`}
            <span className="text-muted-foreground">{"<<"}</span>
          </div>
          {!isZebraScanner && (
            <div className="text-red-500 text-xs border-red-500 border border-dashed p-2 rounded-md my-1">
              <span>Warning: </span>
              IsDatawedgeScanner is currently disabled. Please enable it in the
              settings to utilize the DataWedge scanner functionality.{" "}
              <a href="/settings" className="text-blue-500 mb-3 mt-1">
                {"Settings."}
              </a>
            </div>
          )}
          <div>
            <span className="text-muted-foreground">CLIsDatawedgeScanner </span>
            {`${isZebraScanner ? "true" : "false"}`}
          </div>
        </div>
      </div>
    </div>
  );
};
