import { OriginReference } from "../../../../application/viewModels/CheckBaseViewModel";
import { CommentType } from "../../../../infrastructure/api/common/types";
import { Entity } from "../Entity";
import { PhotoEntity } from "./PhotoEntity";

interface IActivityEntity {
  uid: string;
  message: string;
  author: OriginReference | null;
  commentType: CommentType;
  createdAt: string;
  updatedAt: string;
  photos: PhotoEntity[];
}

export class ActivityEntity extends Entity implements IActivityEntity {
  public readonly message: string;
  public readonly author: OriginReference | null;
  public readonly commentType: CommentType;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly photos: PhotoEntity[];

  protected constructor(activity: IActivityEntity) {
    super(activity.uid);

    this.message = activity.message;
    this.author = activity.author;
    this.commentType = activity.commentType;
    this.createdAt = activity.createdAt;
    this.updatedAt = activity.updatedAt;
    this.photos = activity.photos;
  }

  public static new(activity: Omit<IActivityEntity, "uid">): ActivityEntity {
    return new ActivityEntity({
      uid: "non-existing",
      ...activity,
    });
  }

  public static existing(activity: IActivityEntity): ActivityEntity {
    return new ActivityEntity(activity);
  }
}
