import React, { FocusEventHandler } from "react";
import { TextareaElement } from "./styled";
import { TextareaAutosizeProps } from "react-textarea-autosize";

interface ITextareaFieldProps extends TextareaAutosizeProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus: FocusEventHandler<HTMLTextAreaElement>;
  onBlur: FocusEventHandler<HTMLTextAreaElement>;
}

export const TextareaField = ({
  onChange,
  onFocus,
  onBlur,
  ...props
}: ITextareaFieldProps): JSX.Element => {
  return (
    <TextareaElement
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      {...props}
    />
  );
};
