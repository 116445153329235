import { mdiArrowRight, mdiBarcode } from "@mdi/js";
import Icon from "@mdi/react";
import { Portal } from "react-portal";
import Grid from "../../atoms/Grid";
import TypographyBodyLarge from "../../atoms/TypographyBodyLarge";
import Button from "../Button";
import { ButtonVariant } from "../Button/Button";
import Label from "../Label";
import {
  BackButtonWrapperElement,
  BottomNavigation,
  BottomNavigationButtonContainer,
  ReaderWrapperElement,
  ScannerPopupElement,
  TopNavigation,
} from "./styled";

interface IScannerPopupProps {
  readerId: string;
  onClose: () => void;
  onManualInput?: () => void;
  open: boolean;
  onConfirm: () => void;
  data?: string;
  onDeleteResult: () => void;
  confirmLabel?: string;
}

export const ScannerPopup = ({
  readerId,
  onClose,
  onManualInput,
  open,
  onConfirm,
  data,
  onDeleteResult,
  confirmLabel = "Opslaan",
}: IScannerPopupProps): JSX.Element => {
  return (
    <Portal>
      <ScannerPopupElement open={open}>
        <TopNavigation>
          <BackButtonWrapperElement onClick={onClose}>
            <Icon path={mdiArrowRight} size={1} horizontal />
          </BackButtonWrapperElement>
          <TypographyBodyLarge>Code scannen</TypographyBodyLarge>
        </TopNavigation>
        <BottomNavigation>
          {data && (
            <Label
              icon={mdiBarcode}
              text={data ?? "Scan een code..."}
              deletable
              onDelete={onDeleteResult}
            />
          )}
          <BottomNavigationButtonContainer>
            <Grid columns={onManualInput ? 2 : 1}>
              <Button
                onClick={onConfirm}
                variant={ButtonVariant.filled}
                disabled={data === undefined}
              >
                {confirmLabel}
              </Button>
              {onManualInput && (
                <Button onClick={onManualInput} variant={ButtonVariant.text}>
                  Manueel ingeven
                </Button>
              )}
            </Grid>
          </BottomNavigationButtonContainer>
        </BottomNavigation>
        <ReaderWrapperElement>
          <div
            id={readerId}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </ReaderWrapperElement>
      </ScannerPopupElement>
    </Portal>
  );
};
