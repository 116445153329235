import Icon from "@mdi/react";
import styled from "styled-components/macro";

export const ChecklistSectionElement = styled.div`
  display: grid;

  // border-bottom: 1px solid
  //   ${(props) => props.theme.surfaceVariant.main};
`;

export const ChecklistSectionHeaderElement = styled.div`
  // border-bottom: 1px solid
  //   ${(props) => props.theme.surface.onMain}25;
  display: grid;
  grid-row-gap: 12px;
  align-items: center;
  position: sticky;
  /* top: 56px; */
  top: 0;

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
    /* padding: 24px 0 24px 0; */
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.md - 1}px) {
    // Should be 0 but on sometimes layer below is shown 1px above sticky header.
    top: -1px;
  }

  /* background: ${(props) => props.theme.background.main}; */
  z-index: 1;

  /* border-bottom: 2px solid ${(props) => props.theme.surfaceVariant.main}; */
`;

export const ChecklistSectionHeaderContentContainerElement = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  align-items: center;
`;

interface IProgressBarElementProps {
  progress: number;
}

export const ProgressBarElement = styled.div<IProgressBarElementProps>`
  height: 8px;
  border-radius: 8px;
  width: 100%;
  background: ${(props) => props.theme.surfaceVariant.main};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: ${(props) => props.progress}%;
    background: ${(props) => props.theme.surfaceVariant.onMain};
    height: 8px;
    border-radius: 8px 0 0 8px;
  }
`;

export const ChecklistSectionChecksContainerElement = styled.div``;

interface IStyledChecklistSectionIconElementProps {
  open: boolean;
}

export const StyledChecklistSectionIconElement = styled(
  Icon,
)<IStyledChecklistSectionIconElementProps>`
  transform: rotate(${(props) => (props.open ? "90deg" : "0deg")});
  transition: 0.2s;
  transition-timing-function: ease-out;
`;
