import { ThumbsDown, ThumbsUp } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { checksHooks } from "../../../api";
import { ICheckYesNoViewModel } from "../../../application/viewModels/CheckYesNoViewModel";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupYesNoProps {
  check: ICheckYesNoViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistUid: string;
  groupUid: string;
}

export const CheckPopupYesNo = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistUid,
    groupUid,
  }: ICheckPopupYesNoProps): JSX.Element => {
    const { mutate } = checksHooks.usePut(
      "/checklists/groups/checks/yes-no/value",
    );
    const { mutate: mutateReset } = checksHooks.usePut(
      "/checklists/groups/checks/yes-no/value/reset",
    );
    const { t } = useTranslation();

    const handleYes = async () => {
      let value: null | string = null;

      if (check.commentRequiredOnYes) {
        value = prompt(
          check.commentOnYesTitle ??
            t("inspectionPage.checkPopup.yesNoCheck.onYesTitle"),
        );
      }

      if (!value && check.commentRequiredOnYes) {
        return;
      }

      mutate(
        {
          answer: true,
          commentValue: value ?? undefined,
          checklistUid,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.yes(value ?? undefined);
            onFinish();
          },
        },
      );
    };

    const handleNo = async () => {
      let value: null | string = null;

      if (check.commentRequiredOnNo) {
        value = prompt(
          check.commentOnNoTitle ??
            t("inspectionPage.checkPopup.yesNoCheck.onNoTitle"),
        );
      }

      if (!value && check.commentRequiredOnNo) {
        return;
      }

      mutate(
        {
          answer: false,
          commentValue: value ?? undefined,
          checklistUid,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.no(value ?? undefined);
            onFinish();
          },
        },
      );
    };

    const handleReset = async () => {
      mutateReset(
        {
          checklistUid,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {!signed && check.answer === null && (
            <ActionContainer>
              <PrimaryActionButton onClick={handleYes} disabled={check.loading}>
                {t("inspectionPage.checkPopup.yesNoCheck.yesButton")}
              </PrimaryActionButton>
              <SecondaryActionButton
                onClick={handleNo}
                disabled={check.loading}
              >
                {t("inspectionPage.checkPopup.yesNoCheck.noButton")}
              </SecondaryActionButton>
            </ActionContainer>
          )}

          {check.answer !== null && (
            <AnswerContainer>
              {check.answer && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <ThumbsUp className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.yesNoCheck.yesButton")}
                  {`${
                    check.comment !== null ? `, ${check.comment?.value}` : ""
                  }`}
                </AnswerBadge>
              )}

              {check.answer === false && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <ThumbsDown className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.yesNoCheck.noButton")}
                  {`${
                    check.comment !== null ? `, ${check.comment?.value}` : ""
                  }`}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
