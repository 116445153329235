import { useState } from "react";
import CheckPopupBase from "./index";
// import Dialog from "../../molecules/Dialog";
import { TextCursorInput } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
} from "./CheckStateCard";

interface ICheckPopupInputSingleLineProps {
  check: ICheckInputSingleLineViewModel;
  onFinish: () => void;
  title: string;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupInputSingleLine = observer(
  ({
    check,
    onFinish,
    title,
    signed,
    sectionTitle,
  }: ICheckPopupInputSingleLineProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleSubmit = async (value: string) => {
      await check.store(value);

      setDialogOpen(false);

      if (check.finished) {
        onFinish();
      }
    };

    const handleReset = () => {
      check.reset();
    };
    const { t } = useTranslation();

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.text !== null && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <TextCursorInput className="w-5 h-5 mr-2" />
                {check.text}
              </AnswerBadge>
            </AnswerContainer>
          )}

          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.singleLine.enterText")}
                </DialogTitle>
                <DialogDescription>
                  {check.title.includes(":date:")
                    ? check.title.split(":date:")[1]
                    : check.title}
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleSubmit(value);

                    setValue(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {check.title.includes(":date:") ? (
                    <input
                      autoFocus
                      type="datetime-local"
                      className="px-2 py-1 w-full rounded-md bg-accent text-primary border"
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                    />
                  ) : (
                    <Input
                      autoFocus
                      className="px-2 py-1 w-full rounded-md bg-accent text-primary border"
                      placeholder={t(
                        "inspectionPage.checkPopup.singleLine.enterText",
                      )}
                      type="text"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  <div>
                    <button
                      disabled={!value}
                      className={`mt-3 text-sm border h-10 text-primary rounded-md w-full ${
                        !value ? "opacity-50" : ""
                      }`}
                      type="submit"
                    >
                      {t("inspectionPage.checkPopup.singleLine.saveButton")}
                    </button>
                  </div>
                </form>
              </div>
            </DialogContent>

            {!signed && !check.text && (
              <DialogTrigger asChild>
                <ActionContainer>
                  <PrimaryActionButton
                    disabled={check.loading}
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                  >
                    {title}
                  </PrimaryActionButton>
                </ActionContainer>
              </DialogTrigger>
            )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
