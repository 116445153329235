import { Ban, CheckCircle, RotateCcw } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ICheckOkNotOkViewModel } from "../../../application/viewModels/CheckOkNotOkViewModel";
import { OperatorRoutePath } from "../../../data";
import {
  CheckState,
  CommentType,
} from "../../../infrastructure/api/common/types";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupOkNotOkProps {
  check: ICheckOkNotOkViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupOkNotOk = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
  }: ICheckPopupOkNotOkProps): JSX.Element => {
    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const handleAccept = async () => {
      await check.accept();

      onFinish();
    };

    const handleReject = async () => {
      navigation(
        `${OperatorRoutePath.CheckActivityBase}/${
          CommentType.Rejected
        }/?projectId=${searchParams.get("projectId")}&check-popup=open`,
      );
    };

    const handleRecover = async () => {
      navigation(
        `${OperatorRoutePath.CheckActivityBase}/${
          CommentType.Recovered
        }/?projectId=${searchParams.get("projectId")}&check-popup=open`,
      );
    };

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.state !== CheckState.Empty && (
            <AnswerContainer>
              {check.state === CheckState.Good && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={() => check.reset()}
                >
                  <CheckCircle className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.accepted")}
                </AnswerBadge>
              )}

              {check.state === CheckState.Bad && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel="Herstellen"
                  onReset={handleRecover}
                >
                  <Ban className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.rejected")}
                </AnswerBadge>
              )}

              {check.state === CheckState.Recovered && (
                <AnswerBadge
                  canDelete={false}
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={() => check.reset()}
                >
                  <RotateCcw className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.recovered")}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          {check.state !== CheckState.Bad &&
            !signed &&
            check.state === CheckState.Empty && (
              <ActionContainer>
                <PrimaryActionButton
                  onClick={handleAccept}
                  disabled={check.loading}
                >
                  {t("inspectionPage.checkPopup.okNokCheck.acceptButton")}
                </PrimaryActionButton>
                <SecondaryActionButton
                  onClick={handleReject}
                  disabled={check.loading}
                >
                  {t("inspectionPage.checkPopup.okNokCheck.rejectButton")}
                </SecondaryActionButton>
              </ActionContainer>
            )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
