import styled from "styled-components/macro";

export const ScannerPopupElement = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.background.main};
  z-index: 3000;
  display: ${(props) => (props.open ? "block" : "none")};
`;

export const TopNavigation = styled.div`
  height: 32px;
  width: 100%;
  display: grid;
  align-items: center;
  padding: 24px;
  z-index: 1;
  position: relative;
  grid-template-columns: auto 1fr;
  text-align: center;
`;

export const BottomNavigation = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 36px;
  width: 100%;
  z-index: 2;
`;

export const ReaderWrapperElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BackButtonWrapperElement = styled.div``;

export const BottomNavigationButtonContainer = styled.div`
  background: ${(props) => props.theme.surface.main};
  padding: 24px 0;
  width: 100%;
`;
